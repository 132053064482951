import Modal from 'react-modal';
import React, { Component } from "react";
import './layout/site.css';

function Popup({ headerText, bodyText, showModal, button1Text, button1ClickHandler, showButton2, button2Text, button2ClickHandler }) {
    return (
        <Modal
            isOpen={showModal}
            //onAfterOpen={ }
            onRequestClose={() => { }}
            className="popup"
            contentLabel={headerText}
            overlayClassName="Overlay"
        >
            {headerText && headerText?.length ?
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '5vh',
                    backgroundColor: 'aliceblue',
                    padding: '3px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    marginBottom: '1vh'
                }}>
                    <label style={{ color: '#000', fontSize: '15px', fontWeight: 'bold' }}>{headerText}</label>
                </div>
                : null}
            <div style={{ display: 'flex', justifyContent: 'center', padding: '3px' }}>
                <label style={{ color: '#000', fontSize: '13px', fontWeight: '400' }}>{bodyText}</label>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
                <button
                    className="creditAppNextUpload"
                    onClick={() => button1ClickHandler()}
                >
                    {button1Text == undefined ? "Close" : button1Text}
                </button>
                {showButton2 ?
                    <button
                        className="creditAppNextUpload"
                        onClick={() => button2ClickHandler()}
                    >
                        {button2Text == undefined ? "OK" : button2Text}
                    </button>
                    : null}
            </div>
        </Modal>
    );
}

export default Popup;