import React, { Component } from 'react';
import { _fetch } from './_fetch';
import { Spinner } from 'reactstrap';
import TextInput from './TextInput';
import Toast from './toast.js';
import NavBox from './navbox';
import { customFunctions } from './CustomFunctions.js';

export class SellerInformation extends Component {
    static displayName = SellerInformation.name;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showToast: false,
            toastMessage: '',
            toastItems: [],
            ShowNavBox: false,
            showPrintModal: false,
            TradePayoff: "Yes",
            SellerPayoff: "No"
        }

        //this.termOtherRef = React.createRef();
        //this.loanAmountRef = React.createRef();
    }

    componentDidMount() {
        let id = sessionStorage.getItem("ID");
        if (id !== 0 && id !== null) {
            this.loadPage(id);
            this.setState({ CreditApplicationId: id, ShowNavBox: true });
        }
        else {
            this.setState({ CreditApplicationId: 0, ShowNavBox: true });
        }
    }

    calculateTotals() {
        if (this.state.LoanAmount != undefined) {
            var loanAmount = this.zeroOrValue(this.state.LoanAmount.replace(/,/g, '').replace('$', ''));
            var referralPercentage = this.zeroOrValue(this.state.DealerReferralPercent);

            var dealerReferralAmount = loanAmount * (referralPercentage / 100);

            if (dealerReferralAmount > 0) {
                dealerReferralAmount = Math.round((dealerReferralAmount + Number.EPSILON) * 100.00) / 100.00;

                var totalRaw = this.formatCurrency(dealerReferralAmount);

                if (totalRaw && totalRaw.startsWith('$') && !totalRaw.includes('.')) {
                    totalRaw = totalRaw + '.00';
                }
                else if (totalRaw.startsWith('$') && totalRaw.includes('.')) {
                    var spl = totalRaw.split(".");
                    if (spl[1].length == 0) {
                        totalRaw = totalRaw + '00';
                    }

                    if (spl[1].length == 1) {
                        totalRaw = totalRaw + '0';
                    }
                }

                this.setState({ DealerReferralAmount: totalRaw });
            }
        }
    }

    zeroOrValue(value) {
        if (value == undefined || value == 0 || value == null) return 0;

        var _value = value?.toString().replace(/,/g, '').replace('$', '');

        if (_value.length > 0) {
            var result = (isNaN(_value) || _value == undefined) ? 0 : parseFloat(_value);
            return result;
        }
        else {
            return 0;
        }
    }

    formatCurrency(value, decimals) {
        if (value == undefined) return "";
        var _values = value.toString().replace(/,/g, '').replace('$', '');

        var dec = "";
        var _value = "";

        var elements = _values.split('.');
        if (elements?.length == 1) {
            _value = _values.split('.')[0];
        }
        else if (elements?.length > 1) {
            _value = _values.split('.')[0];
            dec = "." + _values.split('.')[1].substring(0, 2);
        }
        else {
            _value = _values;
        }

        if (_value.length > 0) {
            return new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: decimals == undefined ? 0 : decimals,
                minimumFractionDigits: decimals == undefined ? 0 : decimals,
            }).format(_value) + dec;
        }
    }

    save(url) {
        try {
            this.setState({ loading: true });
            var fetch = _fetch();

            fetch.post("/seller/SaveSeller", this.state)
                .then((id) => {
                    if (id != 0) {
                        if (url) {
                            window.location.href = url;
                        }
                        else {
                            this.setState({
                                loading: false,
                                showToastSuccess: true,
                                toastMessageSuccess: 'Saved successfully'
                            });
                        }
                    }
                    else {
                        this.setState({
                            loading: false,
                            showToast: true,
                            toastMessage: 'There was an issue saving your information. Please try again'
                        });
                    }
                });

        }
        catch (err) {
            this.setState({ loading: false });
            console.log('save - ' + err);
        }
    }

    loadPage(id) {
        try {
            this.setState({ loading: true });
            var fetch = _fetch();
            fetch.get("/seller/GetSeller?id=" + id)
                .then((data) => {
                    var _state = this.state;
                    for (const key in data) {
                        if (data[key] != null) {
                            var _key = key.charAt(0).toUpperCase() + key.slice(1);
                            var _value = data[key];
                            _state[_key] = _value?.toString();
                        }
                    }
                    this.setState(_state, function () {
                        this.calculateTotals();
                        this.setState({ loading: false });
                    });
                })
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('loadPage - ' + err);
        }
    }

    render() {
        return (
            <div className="container-top">
                <NavBox
                    ShowNavBox={this.state.ShowNavBox}
                    ClientNumber={this.state.CreditApplicationId}
                    SaveOnClick={(e) => this.save(e)}
                />
                <Toast
                    toastText={this.state.toastMessage}
                    listItems={this.state.toastItems}
                    showToast={this.state.showToast}
                    onToastClose={() => this.setState({ showToast: false })}
                    autoHideToastDelay={10000}
                />
                <Toast
                    toastText={this.state.toastMessageSuccess}
                    showToast={this.state.showToastSuccess}
                    onToastClose={() => this.setState({ showToastSuccess: false })}
                    autoHideToastDelay={5000}
                    isSuccessToast={true}
                />
                <div className="container-for-fixed-header">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }
                    <table cellPadding={2} cellSpacing={2}>
                        <tbody>
                            <tr>
                                <td align="left" colSpan={2}>
                                    <label style={{ fontSize: '22px', fontWeight: '500', color: '#596db3' }}>Seller Information</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td style={{ fontSize: '15px', fontWeight: '500', paddingTop: '10px' }} align="left" colSpan={2}>
                                                    Seller Information
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        Label="Seller #1 Name"
                                                        TextChanged={(e) => {
                                                            this.setState({ Seller1Name: e == "" ? null : e });
                                                        }}
                                                        Value={this.state.Seller1Name}
                                                        Style={{ width: '260px' }}
                                                        AutoFocus={true}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput type="text"
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ Seller2Name: e })}
                                                        Value={this.state.Seller2Name}
                                                        Label="Seller #2 Name"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ SellerAddress: e == "" ? null : e })}
                                                        Value={this.state.SellerAddress}
                                                        Label="Seller Address"
                                                        Style={{ width: '524px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ SellerEmail: e })}
                                                        Value={this.state.SellerEmail}
                                                        Label="Seller Email"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="phone"
                                                        Label="Seller Phone"
                                                        MaxTextLength={14}
                                                        TextChanged={(e) => {
                                                            var formattedPhone = customFunctions.formatPhoneNumber(e);
                                                            this.setState({ SellerPhone: formattedPhone });
                                                        }}
                                                        Value={customFunctions.formatPhoneNumber(this.state.SellerPhone) ?? ""}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ fontSize: '15px', fontWeight: '500', padding: '15px 0 5px 0' }} align="left">
                                                    Loan Payoff Information
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <div className="radioGroup">
                                                        <input type="radio"
                                                            name="payoff"
                                                            style={{ marginRight: '5px' }}
                                                            onChange={(e) => {
                                                                this.setState({ TradePayoff: e.target.checked ? "Yes" : "No" });
                                                            }}
                                                            defaultChecked={true}
                                                        ></input>
                                                        <label>Trade Payoff</label>
                                                        <input type="radio"
                                                            name="payoff"
                                                            style={{ marginRight: '5px' }}
                                                            onChange={(e) => {
                                                                this.setState({ SellerPayoff: e.target.checked ? "Yes" : "No" });
                                                            }}
                                                        ></input>
                                                        <label>Seller Payoff</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ LoanPayoffBankName: e })}
                                                        Value={this.state.LoanPayoffBankName}
                                                        Label="Bank Name"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="phone"
                                                        Label="Phone #"
                                                        MaxTextLength={14}
                                                        TextChanged={(e) => {
                                                            var formattedPhone = customFunctions.formatPhoneNumber(e);
                                                            this.setState({ LoanPayoffPhone: formattedPhone });
                                                        }}
                                                        Value={customFunctions.formatPhoneNumber(this.state.LoanPayoffPhone) ?? ""}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ LoanPayoffContact: e })}
                                                        Value={this.state.LoanPayoffContact}
                                                        Label="Contact"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ LoanPayoffAccountNumber: e })}
                                                        Value={this.state.LoanPayoffAccountNumber}
                                                        Label="Account #"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="custom"
                                                        Label="Payoff Amount"
                                                        TextChanged={(e) => {
                                                            this.setState({ PayoffAmount: e });
                                                        }}
                                                        Value={this.formatCurrency(this.state.PayoffAmount)}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="date"
                                                        Label="Date"
                                                        TextChanged={(e) => {
                                                            this.setState({ PayoffThroughDate: e });
                                                        }}
                                                        Value={this.state.PayoffThroughDate}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ LoanPayoffPerDiem: e })}
                                                        Value={this.state.LoanPayoffPerDiem}
                                                        Label="Per Diem"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ LoanPayoffAdditionalFundsNeeded: e })}
                                                        Value={this.state.LoanPayoffAdditionalFundsNeeded}
                                                        Label="Additional Funds Needed"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left" colSpan={2}>
                                                    Dealer / Broker Contact Information
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ DealerBrokerName: e })}
                                                        Value={this.state.DealerBrokerName}
                                                        Label="Dealer / Broker Name"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ DealerBrokerPrimaryContact: e })}
                                                        Value={this.state.DealerBrokerPrimaryContact}
                                                        Label="Dealer / Broker Primary Contact"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ DealerBrokerEmail: e })}
                                                        Value={this.state.DealerBrokerEmail}
                                                        Label="Dealer / Broker Email"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="phone"
                                                        Label="Dealer / Broker Phone"
                                                        MaxTextLength={14}
                                                        TextChanged={(e) => {
                                                            var formattedPhone = customFunctions.formatPhoneNumber(e);
                                                            this.setState({ DealerBrokerPhone: formattedPhone });
                                                        }}
                                                        Value={customFunctions.formatPhoneNumber(this.state.DealerBrokerPhone) ?? ""}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                                    Dealer Referral Fee Information
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ DealerReferralName: e })}
                                                        Value={this.state.DealerReferralName}
                                                        Label="Dealer Referral Name"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="number"
                                                        Label="Dealer Referral %"
                                                        TextChanged={(e) => {
                                                            this.setState({ DealerReferralPercent: e == "" ? null : e }, function () {
                                                                if (this.state.DealerReferralPercent == null) {
                                                                    this.setState({ DealerReferralAmount: "" });
                                                                }
                                                                else {
                                                                    this.calculateTotals();
                                                                }
                                                            });
                                                        }}
                                                        Value={this.state.DealerReferralPercent}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Disabled={true}
                                                        Type="text"
                                                        Label="Dealer Referral $ Amount"
                                                        TextChanged={(e) => {
                                                            this.setState({ DealerReferralAmount: e == "" ? null : e });
                                                        }}
                                                        Value={this.state.DealerReferralAmount}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                                    Documentation Service Information
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ DocTitleServiceName: e })}
                                                        Value={this.state.DocTitleServiceName}
                                                        Label="Doc / Title Service Name"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ DocTitleServiceEmail: e })}
                                                        Value={this.state.DocTitleServiceEmail}
                                                        Label="Doc / Title Service Email"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="phone"
                                                        Label="Doc / Title Service Phone"
                                                        MaxTextLength={14}
                                                        TextChanged={(e) => {
                                                            var formattedPhone = customFunctions.formatPhoneNumber(e);
                                                            this.setState({ DocTitleServicePhone: formattedPhone });
                                                        }}
                                                        Value={customFunctions.formatPhoneNumber(this.state.DocTitleServicePhone) ?? ""}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="custom"
                                                        Label="Documentation Fee"
                                                        TextChanged={(e) => {
                                                            this.setState({ DocumentationFee: e });
                                                        }}
                                                        Value={this.formatCurrency(this.state.DocumentationFee) ?? ""}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="custom"
                                                        Label="Title Fee"
                                                        TextChanged={(e) => {
                                                            this.setState({ TitleFee: e });
                                                        }}
                                                        Value={this.formatCurrency(this.state.TitleFee) ?? ""}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                                <td>
                                                    <TextInput
                                                        Type="custom"
                                                        Label="Other Fee"
                                                        TextChanged={(e) => {
                                                            this.setState({ OtherFee: e });
                                                        }}
                                                        Value={this.formatCurrency(this.state.OtherFee) ?? ""}
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={2} cellSpacing={2}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={3} style={{ fontSize: '15px', fontWeight: '500', paddingTop: '15px' }} align="left">
                                                    Borrower LLC / Trust Information
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ LlcTrustName: e })}
                                                        Value={this.state.LlcTrustName}
                                                        Label="LLC / Trust Name"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ LlcTrustAddress: e })}
                                                        Value={this.state.LlcTrustAddress}
                                                        Label="LLC / Trust Address"
                                                        Style={{ width: '524px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="number"
                                                        TextChanged={(e) => this.setState({ LlcTrustEIN: e })}
                                                        Value={this.state.LlcTrustEIN}
                                                        Label="EIN"
                                                        Style={{ width: '260px' }}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        TextChanged={(e) => this.setState({ LlcTrustNameTitle: e })}
                                                        Value={this.state.LlcTrustNameTitle}
                                                        Label="LLC Signer Name / Title"
                                                        Style={{ width: '524px' }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <table style={{ width: '100%', height: '100%' }}>
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading ? true : false}
                                        onClick={() => {
                                            this.save();
                                        }}>
                                        Save
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}