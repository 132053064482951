import React, { Component } from 'react';
import { _fetch } from './_fetch';
import { Spinner } from 'reactstrap';
import authService from './api-authorization/AuthorizeService';
import Toast from './toast.js';
import TextInput from './TextInput';

export class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    async componentDidMount() {
        sessionStorage.removeItem("INTERNAL_SESSION");
        sessionStorage.removeItem("NEW_APP");
        sessionStorage.removeItem("ID")

        await authService.getUserId()
            .then((id) => {
                sessionStorage.setItem("USER_ID", id);
                this.search();
            });
    }

    async search() {
        try {
            this.setState({ loading: true });

            const f = new _fetch();

            await authService.getUser()
                .then((user) => {
                    if (user && user.name) {
                        f.fetchValue("/creditapplication/getsalesrepid?id=" + user.name)
                            .then((data) => {
                                sessionStorage.setItem("SALES_REP_ID", data);
                            });
                    }
                });

            var l = this.state.LastName ?? "";
            var v = this.state.VesselManufacturer ?? "";
            var h = this.state.HullIdentificationNumber ?? "";
            var c = this.state.ClientId ?? "";
            var dateFrom = this.state.DateFrom ?? "";
            var dateTo = this.state.DateTo ?? "";
            var userid = sessionStorage.getItem("USER_ID");

            f.get("/creditapplication/SearchLoanApplications?l=" + l + "&v=" + v + "&h=" + h + "&c=" + c + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo + "&userid=" + userid)
                .then((data) => {
                    this.setState({ SearchResults: data, loading: false })
                });

        }
        catch (err) {
            this.setState({ loading: false });
            console.log('search - ' + err);
        }
    }

    createLoanApplication(url) {
        this.setState({ loading: true });
        var fetch = _fetch();

        var data = {
            InteralNewLoanApplication: true
        };

        fetch.post("/creditapplication/SaveCreditApplicationStep1", data)
            .then((id) => {
                if (id != 0) {
                    this.setState({ loading: false });
                    sessionStorage.setItem("ID", id);
                    window.location.href = url;
                }
                else {
                    this.setState({
                        showToast: true,
                        toastMessage: 'There was an issue creating a new loan application. Please try again',
                        loading: false
                    });
                }
            })
    }

    render() {
        return (
            <div className="container-top">
                <Toast
                    toastText={this.state.toastMessage}
                    listItems={this.state.toastItems}
                    showToast={this.state.showToast}
                    onToastClose={() => this.setState({ showToast: false })}
                    autoHideToastDelay={10000}
                />
                <div className="container-for-fixed-header">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }
                    <div style={{ width: '56vw', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginTop: '4vh' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '1vh' }}>
                            <input
                                type="number"
                                style={{ width: '8vw', marginRight: '7px', fontSize: '12px', minHeight: '26px', verticalAlign: 'middle' }}
                                onChange={(e) => {
                                    this.setState({ ClientId: e.target.value });
                                }}
                                value={this.state.ClientId}
                                max={999999}
                                placeholder="Client #"
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.search();
                                    }
                                }}
                            ></input>
                            <input
                                type="text"
                                style={{ width: '8vw', marginRight: '7px', fontSize: '12px', minHeight: '26px', verticalAlign: 'middle' }}
                                onChange={(e) => {
                                    this.setState({ LastName: e.target.value });
                                }}
                                value={this.state.LastName}
                                maxLength={15}
                                placeholder="Last Name"
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.search();
                                    }
                                }}
                            ></input>
                            <input
                                type="text"
                                maxLength={12}
                                style={{ width: '8vw', marginRight: '7px', fontSize: '12px', minHeight: '26px', verticalAlign: 'middle' }}
                                onChange={(e) => {
                                    this.setState({ VesselManufacturer: e.target.value });
                                }}
                                value={this.state.VesselManufacturer}
                                placeholder="Vessel Manufacturer"
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.search();
                                    }
                                }}
                            ></input>
                            <input
                                type="text"
                                maxLength={15}
                                style={{ width: '8vw', marginRight: '7px', fontSize: '12px', minHeight: '26px', verticalAlign: 'middle' }}
                                onChange={(e) => {
                                    this.setState({ HullIdentificationNumber: e.target.value });
                                }}
                                value={this.state.HullIdentificationNumber}
                                placeholder="HIN"
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        this.search();
                                    }
                                }}
                            ></input>
                            <button
                                className="creditAppNextUpload"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={this.state.loading ? true : false}
                                onClick={() => {
                                    this.search();
                                }}>
                                Search
                            </button>
                            <button
                                className="creditAppNextUpload"
                                style={{ whiteSpace: 'nowrap', marginLeft: '5px' }}
                                disabled={this.state.loading ? true : false}
                                onClick={() => {
                                    this.setState({
                                        ClientId: '',
                                        LastName: '',
                                        VesselManufacturer: '',
                                        HullIdentificationNumber: '',
                                        DateFrom: undefined,
                                        DateTo: undefined
                                    });
                                    this.search();
                                }}>
                                Refresh
                            </button>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '1vh', marginLeft: '7px' }}>
                            <button
                                className="creditAppNextUpload"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={this.state.loading ? true : false}
                                onClick={() => {
                                    sessionStorage.setItem("NEW_APP", "true");
                                    this.createLoanApplication('/CreditApplicationStep1');
                                }}>
                                New Loan Application
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '1vh' }}>
                        <table className="dateRangeTable">
                            <tbody>
                                <tr>
                                    <td>
                                        <TextInput
                                            Type="date2"
                                            Label="Date From"
                                            TextChanged={(e) => {
                                                this.setState({ DateFrom: e });
                                            }}
                                            Value={this.state.DateFrom}
                                            Style={{ width: '8vw' }}
                                        />
                                    </td>
                                    <td>
                                        <TextInput
                                            Type="date2"
                                            Label="Date To"
                                            TextChanged={(e) => {
                                                this.setState({ DateTo: e });
                                            }}
                                            Value={this.state.DateTo}
                                            Style={{ width: '8vw' }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ maxWidth: '56vw', maxHeight: '31.5vh', overflow: 'auto' }}>
                        <table className="gridTable" style={{ maxWidth: '56vw' }}>
                            <thead>
                                <tr>
                                    <th>Customer Last Name</th>
                                    <th>Client #</th>
                                    <th>Status</th>
                                    <th>State</th>
                                    <th>Loan Amount</th>
                                    <th>Vessel Manufacturer</th>
                                    <th>Sales Rep</th>
                                    <th>Date Submitted</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.SearchResults && this.state.SearchResults?.length > 0 ?
                                    this.state.SearchResults?.map((item, idx) =>
                                        <tr key={idx}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                sessionStorage.setItem("ID", item.id);
                                                sessionStorage.setItem("INTERNAL_SESSION", "1");
                                                window.location.href = '/CreditApplicationStep1'
                                            }
                                            }>
                                            <td>{item.lastName}</td>
                                            <td>{item.clientNumber}</td>
                                            <td>{item.status}</td>
                                            <td>{item.state}</td>
                                            <td>{item.loanAmount}</td>
                                            <td>{item.vesselManufacturer}</td>
                                            <td>{item.salesRep}</td>
                                            <td>{item.dateSubmitted}</td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={8} align="center">No search results found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="footer">
                    <table style={{ width: '100%', height: '100%' }}>
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}