import React from "react";
import './toast.css';

function Toast({ toastText, showToast, listItems, onToastClose, autoHideToastDelay, isSuccessToast }) {
    if (autoHideToastDelay && autoHideToastDelay > 0 && showToast == true) {
        setTimeout(function () {
            onToastClose()
        }, autoHideToastDelay);
    }

    return (
        <div className="toast toast-label-text popout" style={showToast ? {} : { display: 'none' }}>
            <div className={isSuccessToast ? "toast-shell-success" : "toast-shell-error"}>
                <div className={isSuccessToast ? "closeButtonContainerCenter" : "closeButtonContainer"}>
                    <div
                        className={isSuccessToast ? "modalCloseSuccess" : "modalClose"}
                        onClick={() => {
                            onToastClose()
                        }}>
                    </div>
                </div>
                <div className="toastBody">
                    <label>{toastText}</label>
                </div>
                {listItems && listItems?.length > 0 ?
                    <div className="toastList">
                        <ul>
                            {listItems?.map((item, idx) => <li key={idx}>{item}</li>)}
                        </ul>
                    </div>
                    : null}
            </div>
        </div>
    )
}

export default Toast;