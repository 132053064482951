import React, { Component } from 'react';
import { Spinner } from 'reactstrap';
import Upload from "./upload-files-vault";
import NavBox from './navbox';
import { _fetch } from './_fetch';

export class DocumentVault extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            ShowNavBox: false
        };
    }

    componentDidMount() {
        var id = sessionStorage.getItem("ID");
        this.setState({ CreditApplicationId: id, ShowNavBox: true });
    }

    render() {
        return (
            <div className="container-top">
                {this.state.CreditApplicationId !== undefined ?
                    <NavBox
                        ShowNavBox={this.state.ShowNavBox}
                        ClientNumber={this.state.CreditApplicationId}
                        SaveOnClick={(e) => { window.location.href = e }}
                    />
                    : null}
                <div className="container-for-fixed-header">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }
                    {this.state.CreditApplicationId !== undefined ?
                        <Upload CreditApplicationId={this.state.CreditApplicationId} />
                        : null}
                </div>
                <div className="footer">

                </div>
            </div>
        )
    }
}
