import React, { Component } from "react";
import UploadService from "../services/upload-files-vault.service";
import '../custom.css';
import { IoTrashOutline } from 'react-icons/io5';
import { MdDownloadForOffline } from 'react-icons/md';
import Modal from 'react-modal';
import { _fetch } from './_fetch';
import authService from './api-authorization/AuthorizeService';
import { BiUndo } from "react-icons/bi";
import Toast from './toast.js';

export default class UploadFiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: undefined,
            progressInfos: [],
            message: [],
            fileInfos: [],
            trashColor: '#000',
            documentTypes: [],
            DocumentTypeId: 0,
            Notes: '',
            FileName: '',
            showRecycleBinModal: false,
            disableRecycleBin: true,
            deletedFiles: [],
            restoreUploadId: undefined,
            showRestoreModal: false,
            recycleCount: ''
        };

        this.selectFile = this.selectFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.fileInputRef = React.createRef()
    }

    componentDidMount() {
        UploadService.getDocumentTypes()
            .then((response) => {
                this.setState({
                    documentTypes: response.data,
                }, function () {
                    this.loadUploadFiles();
                });
            });
    }

    loadUploadFiles() {
        UploadService.getFiles(this.props.CreditApplicationId).then((response) => {
            this.setState({
                fileInfos: response.data[0]?.id !== 0 ? response.data : undefined
            });

            if (response.data.length > 0) {
                if (response.data[0]?.deletedCount === 0) {
                    this.setState({ disableRecycleBin: true, recycleCount: "" });
                }
                else {
                    this.setState({ disableRecycleBin: false, recycleCount: response.data[0]?.deletedCount.toString() });
                }
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.CreditApplicationId !== this.props.CreditApplicationId) {
            UploadService.getFiles(this.props.CreditApplicationId).then((response) => {
                this.setState({
                    fileInfos: response.data[0]?.id !== 0 ? response.data : undefined,
                    selectedFiles: undefined,
                    DocumentTypeId: 0
                });
                if (response.data.length > 0) {
                    if (response.data[0].deletedCount === 0) {
                        this.setState({ disableRecycleBin: true, recycleCount: "" });
                    }
                    else {
                        this.setState({ disableRecycleBin: false, recycleCount: response.data[0]?.deletedCount.toString() });
                    }
                }
            });
        }
    }

    selectFile(event) {
        this.setState({
            progressInfos: [],
            selectedFiles: event.target.files,
        });
    }

    uploadFiles() {
        const selectedFiles = this.state.selectedFiles;
        const documentTypeId = this.state.DocumentTypeId;
        const notes = this.state.Notes;
        const _fileName = this.state.FileName;

        let _progressInfos = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
        }
        this.setState(
            {
                progressInfos: _progressInfos,
                message: [],
            },
            () => {
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.upload(i, selectedFiles[i], this.props.CreditApplicationId, documentTypeId, notes, _fileName);
                }
            }
        );
    }

    upload(idx, file, creditApplicationId, documentTypeId, notes, _fileName) {
        let _progressInfos = [...this.state.progressInfos];
        UploadService.upload(file, creditApplicationId, documentTypeId, notes, _fileName, (event) => {
            _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
            this.setState({
                _progressInfos,
            });
        })
            .then((response) => {
                if (response.data.status === "A file with the same name already exists") {
                    this.setState({
                        toastMessage: response.data.status,
                        showToast: true
                    });
                }
                else {
                    this.setState((prev) => {
                        let nextMessage = [...prev.message, "Uploaded the file successfully: " + file.name];
                        return {
                            message: nextMessage
                        };
                    });
                    return UploadService.getFiles(creditApplicationId);
                }
            })
            .then((files) => {
                if (files) {
                    this.setState({
                        fileInfos: files.data,
                    });
                }
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Could not upload the file: " + file.name];
                    return {
                        progressInfos: _progressInfos,
                        message: nextMessage
                    };
                });
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({
                        selectedFiles: undefined,
                        progressInfos: [],
                        message: [],
                        DocumentTypeId: 0,
                        Notes: '',
                        FileName: ''
                    }, function () {
                        this.fileInputRef.current.value = ""
                    });
                }, 3000);
            });
    }

    deleteUpload(id) {
        this.setState({ uploadId: id, showModal: true });
    }

    showRestoreUploadModal(id) {
        this.setState({ restoreUploadId: id, showRestoreModal: true });
    }

    async restoreUpload(id) {
        const token = await authService.getAccessToken();

        await fetch('upload/RestoreDeletedUpload?id=' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            return response.json();
        })
            .then(async (data) => {
                await UploadService.getFiles(this.props.CreditApplicationId).then((files) => {
                    this.setState({
                        fileInfos: files.data[0]?.id !== 0 ? files.data : undefined
                    }, function () {
                        if (files.data[0]?.deletedCount === 0) {
                            this.setState({
                                disableRecycleBin: true,
                                showRecycleBinModal: false,
                                recycleCount: ""
                            });
                        }
                        else {
                            this.setState({ disableRecycleBin: false, recycleCount: files.data[0]?.deletedCount.toString() },
                                async function () {
                                    const token = await authService.getAccessToken();

                                    await fetch('upload/GetRecycleBinFiles?creditApplicationId=' + this.props.CreditApplicationId, {
                                        method: 'GET',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': 'Bearer ' + token
                                        }
                                    }).then((response) => {
                                        return response.json();
                                    }).then((data) => {
                                        this.setState({ deletedFiles: data, showRecycleBinModal: data.length > 0 });
                                    });
                                });
                        }
                    });
                });
            });
    }

    async loadRecycleBin(id) {
        const token = await authService.getAccessToken();

        await fetch('upload/GetRecycleBinFiles?creditApplicationId=' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            return response.json();
        }).then((data) => {
            this.setState({ deletedFiles: data, showRecycleBinModal: true });
        });
    }

    async deleteUploadedDocument(id) {
        this.setState({ showModal: false });
        const token = await authService.getAccessToken();

        await fetch('upload/DeleteUploadFile?id=' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(async (response) => {
            await UploadService.getFiles(this.props.CreditApplicationId).then((files) => {
                this.setState({
                    fileInfos: files.data[0]?.id !== 0 ? files.data : undefined,
                    DocumentTypeId: 0,
                    FileName: '',
                    Notes: ''
                });
                if (files.data[0]?.deletedCount === 0) {
                    this.setState({ disableRecycleBin: true, recycleCount: "" });
                }
                else {
                    this.setState({ disableRecycleBin: false, recycleCount: files.data[0]?.deletedCount.toString() });
                }
            });
        });
    }

    async download(id) {
        const token = await authService.getAccessToken();
        var tempfilename = "download.pdf";
        var filename = "";
        var contentType = "application/pdf";

        var f = _fetch();
        await fetch("/Upload/DownloadFile?id=" + id, {
            method: 'GET',
            body: null,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then((res) => {
                const header = res.headers.get('Content-Disposition');
                const parts = header.split(';');
                contentType = res.headers.get("Content-Type");

                if (parts[1]) {
                    filename = parts[1].split('=')[1];
                }
                else {
                    filename = tempfilename;
                }
                return res.blob();
            })
            .then(data => {
                f.downloadFile(data, filename, contentType);
            })
            .catch((error) => {
                console.log('error in download - ' + error.message);
            });

    }

    render() {
        const { selectedFiles, progressInfos, message, fileInfos, documentTypes } = this.state;
        return (
            <div style={{ width: '55vw' }}>
                <Toast
                    toastText={this.state.toastMessage}
                    showToast={this.state.showToast}
                    onToastClose={() => this.setState({ showToast: false })}
                    autoHideToastDelay={7000}
                />
                <table className="docVaultTable">
                    <tbody>
                        <tr>
                            <td>
                                Document Type
                            </td>
                            <td>
                                <select
                                    value={this.state.DocumentTypeId}
                                    style={{ width: '16vw' }}
                                    onChange={(e) => {
                                        this.setState({ DocumentTypeId: e.target.value });
                                    }}
                                >
                                    <option key={0} value={0}>Select Document Type</option>
                                    {documentTypes && documentTypes.map((item, idx) =>
                                        <option key={idx} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                Notes
                            </td>
                            <td>
                                <textarea
                                    style={{ width: '16vw', resize: 'none' }}
                                    placeholder=""
                                    onChange={(e) => {
                                        this.setState({ Notes: e.target.value })
                                    }}
                                    value={this.state.Notes}
                                ></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                File Name
                            </td>
                            <td>
                                <input type="text"
                                    style={{ width: '16vw' }}
                                    placeholder=""
                                    onChange={(e) => {
                                        this.setState({ FileName: e.target.value })
                                    }}
                                    onKeyPress={(e) => {
                                        var illegal = '<>:"/\\|?*';
                                        if (illegal.includes(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    value={this.state.FileName}
                                ></input>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                        <tr>
                            <td valign="middle">
                                <label className="uploadLabel" style={{ marginTop: '10px' }}>
                                    <input ref={this.fileInputRef}
                                        type="file" accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt,.csv,.ppt,.pptx,.odt,.ods"
                                        onChange={this.selectFile} />
                                </label>
                            </td>
                            <td valign="middle">
                                <button
                                    className="creditAppNextUpload"
                                    style={{ marginTop: '10px' }}
                                    disabled={!selectedFiles || this.state.DocumentTypeId === 0}
                                    onClick={this.uploadFiles}
                                >Upload
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                        <div className="mb-2" key={index}>
                            <span>{progressInfo.fileName}</span>
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-info"
                                    role="progressbar"
                                    aria-valuenow={progressInfo.percentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: progressInfo.percentage + "%" }}
                                >
                                    {progressInfo.percentage}%
                                </div>
                            </div>
                        </div>
                    ))}
                {
                    message.length > 0 && (
                        <div className="alert alert-secondary" role="alert">
                            <ul>
                                {message.map((item, i) => {
                                    return <li key={i}>{item}</li>;
                                })}
                            </ul>
                        </div>
                    )
                }
                <div>
                    <hr />
                    <table style={{ width: '55vw' }}>
                        <tbody>
                            <tr>
                                <td align="right">
                                    <div style={{ width: 'fit-content', height: 'fit-content', position: 'relative' }}>
                                        <button
                                            className="creditAppNext"
                                            disabled={this.state.disableRecycleBin}
                                            onClick={async () => {
                                                await this.loadRecycleBin(this.props.CreditApplicationId);
                                            }}
                                        >Recycle Bin</button>
                                        <div className="badge">{this.state.recycleCount}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="docVaultUploadTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Document Type</th>
                                <th>File Name</th>
                                <th>Notes</th>
                                <th>Date Uploaded</th>
                                <th># Pages</th>
                                <th>Download</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.fileInfos && this.state.fileInfos?.length > 0 ?
                                this.state.fileInfos?.map((item, idx) =>
                                    <tr key={idx}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {

                                        }
                                        }>
                                        <td align="center" valign="middle">
                                            <img src={item.iconUrl} style={{ width: '21px' }} />
                                        </td>
                                        <td>{item.dtName}</td>
                                        <td>{item.filename}</td>
                                        <td>{item.notes}</td>
                                        <td>{item.dateUploaded}</td>
                                        <td align="center" valign="middle">{item.pages}</td>
                                        <td align="center" valign="middle">
                                            <MdDownloadForOffline
                                                color="#33C1FF"
                                                size={24}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => { this.download(item.id) }}
                                            />
                                        </td>
                                        <td align="center" valign="middle">
                                            <IoTrashOutline
                                                color={this.state.trashColor}
                                                size={21}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => this.deleteUpload(item.id)}
                                            />
                                        </td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td colSpan={8} align="center">No documents found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showModal: false })}
                        className="et-modal"
                        contentLabel="Delete Upload"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h6>Delete the uploaded document?</h6>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '15px' }}>
                            <button
                                className="creditAppNextUpload"
                                onClick={() => this.setState({ showModal: false })}
                            >
                                No
                            </button>
                            <button
                                className="creditAppNextUpload"
                                onClick={() => this.deleteUploadedDocument(this.state.uploadId)}
                            >
                                Yes
                            </button>
                        </div>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showRestoreModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showRestoreModal: false })}
                        className="modal-front"
                        contentLabel="Restore Document"
                        overlayClassName="Overlay2"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h6>Restore deleted document?</h6>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '15px' }}>
                            <button
                                className="creditAppNextUpload"
                                onClick={() => this.setState({ showRestoreModal: false })}
                            >
                                No
                            </button>
                            <button
                                className="creditAppNextUpload"
                                onClick={() => {
                                    this.setState({ showRestoreModal: false })
                                    this.restoreUpload(this.state.restoreUploadId);
                                }}
                            >
                                Yes
                            </button>
                        </div>
                    </Modal>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showRecycleBinModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showRecycleBinModal: false })}
                        className="et-modal"
                        contentLabel="Recycle Bin"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                            <h6>Recycle Bin</h6>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '15px' }}>
                            <table className="recycleBinTable">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Document Type</th>
                                        <th>Date Deleted</th>
                                        <th># Pages</th>
                                        <th>Restore</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.deletedFiles && this.state.deletedFiles?.length > 0 ?
                                        this.state.deletedFiles?.map((item, idx) =>
                                            <tr key={idx}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {

                                                }}>
                                                <td align="center" valign="middle">
                                                    <img src={item.iconUrl} style={{ width: '21px' }} />
                                                </td>
                                                <td>{item.dtName}</td>
                                                <td>{item.dateDeleted}</td>
                                                <td>{item.pages}</td>
                                                <td align="center" valign="middle">
                                                    <BiUndo
                                                        color={'#00ff00'}
                                                        size={23}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => this.showRestoreUploadModal(item.id)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={8} align="center">No documents found</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Modal>
                </div>
            </div >
        );
    }
}