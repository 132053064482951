import http from "../http-common";
import authService from '../components/api-authorization/AuthorizeService';

class UploadFilesService {
    async upload(file, documentTypeId, continueToken, notes, onUploadProgress) {
        //const token = await authService.getAccessToken();
        let formData = new FormData();
        formData.append("file", file);
        formData.append("documentTypeId", documentTypeId);
        formData.append("continueToken", continueToken);
        formData.append("notes", notes);
        formData.append("isApplicantUpload", true);
        return http.post("uploadpublic/uploadfilePublic", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                //"Authorization": `Bearer ${token}`
            },
            onUploadProgress,
        });
    }
    async getFiles(continueToken) {
        //const token = await authService.getAccessToken();
        return http.get("uploadpublic/getfilesPublic?id=" + continueToken + "&isApplicantUpload=true", {
            headers: {
                //"Authorization": `Bearer ${token}`
            }
        });
    }

    async getDocumentTypes() {
        //const token = await authService.getAccessToken();
        return http.get("uploadpublic/GetApplicantUploadDocumentTypes", {
            headers: {
                //"Authorization": `Bearer ${token}`
            }
        });
    }
}
export default new UploadFilesService();