import React, { Component } from 'react';
import { _fetch } from './_fetch';
import { Spinner } from 'reactstrap';
import UploadPublic from "./upload.applicant.component";

export class ApplicantUpload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            ContinueToken: undefined,
            CreditApplicationId: undefined,
            authenticated: false,
            showToast: false,
            toastMessage: ''
        }
    }

    componentDidMount() {
        var authenticated = (sessionStorage.getItem("AUTH_35678hsysla") === 'true');
        var continueToken = sessionStorage.getItem("ContinueToken");
        var firstName = sessionStorage.getItem("FirstName");
        var lastName = sessionStorage.getItem("LastName");

        this.setState({
            authenticated: authenticated,
            ContinueToken: continueToken,
            FirstName: firstName,
            LastName: lastName
        });
    }

    identifyApplicant() {
        try {
            var errors = [];

            if (this.state.LastName === undefined || this.state.LastName?.length === 0) {
                errors.push("Last Name")
            }
            if (this.state.Last4SSN === undefined || this.state.Last4SSN?.length === 0 || this.state.Last4SSN?.length < 4) {
                errors.push("Last 4 of SSN")
            }
            if (this.state.ZipCode === undefined || this.state.ZipCode?.length === 0 || this.state.ZipCode?.length < 5) {
                errors.push("Zip Code")
            }

            if (errors.length === 0) {
                this.setState({ authenticated: false, loading: true, toastItems: undefined });

                var fetch = _fetch();

                fetch.post("/CreditApplicationPublic/IdentifyApplicant", this.state)
                    .then((response) => {
                        if (response) {
                            if (response.authenticated === true) {
                                sessionStorage.setItem("AUTH_35678hsysla", true);
                                sessionStorage.setItem("ContinueToken", response.continueToken);
                                sessionStorage.setItem("FirstName", response.firstName);
                                sessionStorage.setItem("LastName", response.lastName);
                                this.setState({
                                    authenticated: true,
                                    loading: false,
                                    ContinueToken: response.continueToken,
                                    FirstName: response.firstName,
                                    LastName: response.lastName
                                });
                            }
                            else {
                                this.setState({
                                    authenticated: false,
                                    toastText: 'No record matches the information provided. Please check your information and try again.',
                                    loading: false,
                                    showToast: true,
                                });
                            }
                        }
                    });
            }
            else {
                this.setState({
                    authenticated: false,
                    toastText: 'The following required fields are invalid or empty:',
                    listItems: errors,
                    loading: false,
                    showToast: true,
                });
            }
        }
        catch {
            this.setState({ authenticated: false, loading: false });
        }
    }

    render() {
        return (
            <div className="container-top">
                <div className="container-for-fixed-header">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }
                    {this.state.authenticated ?
                        <div>
                            <div style={{ width: 'fit-content', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <h5>Hello {this.state.FirstName} {this.state.LastName}</h5>
                                <h5>Please use the fields below to upload your files.</h5>
                                <br />
                                <UploadPublic CreditApplicationId={this.state.CreditApplicationId} ContinueToken={this.state.ContinueToken} />
                                <br />
                            </div>
                        </div>
                        :
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <h6>Please provide the information in the fields below so we can verify your identity.</h6>
                            <br />
                            <table style={{ width: 'fit-content' }}>
                                <tbody>
                                    <tr>
                                        <td valign="top">
                                            <table className="applicantUploadTable">
                                                <tbody>
                                                    <tr>
                                                        <td>Last Name</td>
                                                        <td>
                                                            <input type="text" onChange={(e) => this.setState({ LastName: e.target.value, showToast: false })} autoFocus></input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Last 4 of SSN</td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                onChange={(e) => this.setState({ Last4SSN: e.target.value?.toString(), showToast: false })}
                                                                onKeyDown={(e) => {
                                                                    var val = e.target.value;
                                                                    if (val == undefined) val = "";

                                                                    if (val.length == 4 && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();
                                                                }}>
                                                            </input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Zip Code</td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                onChange={(e) => this.setState({ ZipCode: e.target.value?.toString(), showToast: false })}
                                                                onKeyDown={(e) => {
                                                                    var val = e.target.value;
                                                                    if (val == undefined) val = "";

                                                                    if (val.length == 5 && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();
                                                                }}>
                                                            </input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td align="right">
                                                            <button
                                                                className="creditAppNext"
                                                                style={{ whiteSpace: 'nowrap' }}
                                                                disabled={this.state.loading}
                                                                onClick={() => {
                                                                    this.setState({ listItems: [] })
                                                                    this.identifyApplicant();
                                                                }}>
                                                                Submit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                        <td valign="top">
                                            <div style={this.state.showToast ? { width: 'fit-content', marginTop: '3px', marginLeft: '20px', border: '1px solid #ff000000', borderRadius: '5px' } : { display: 'none' }}>
                                                <div className="toast-shell-error">
                                                    <div className="toastBody">
                                                        <label>{this.state.toastText}</label>
                                                    </div>
                                                    {this.state.listItems && this.state.listItems?.length > 0 ?
                                                        <div className="toastList">
                                                            <ul>
                                                                {this.state.listItems?.map((item, idx) => <li key={idx}>{item}</li>)}
                                                            </ul>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className="footer">
                    <table style={{ width: '100%', height: '100%' }}>
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading ? true : false}
                                        onClick={() => {
                                            sessionStorage.removeItem("AUTH_35678hsysla");
                                            sessionStorage.removeItem("ContinueToken");
                                            sessionStorage.removeItem("FirstName");
                                            sessionStorage.removeItem("LastName");
                                            window.location.href = 'https://fairwindfinancial.com/';
                                        }}>
                                        Close
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

