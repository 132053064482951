import React, { Component } from 'react';
import { Route } from 'react-router';
import { AppBody } from './components/AppBody';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Roles } from './components/admin/Roles';
import { CreateRole } from './components/admin/CreateRole';
import { AccessDenied } from './components/util/AccessDenied';
import './custom.css'
import { Users } from './components/admin/Users';
import { Clients } from './components/admin/Clients';
import { RoleDetails } from './components/admin/RoleDetails';
import { UserDetails } from './components/admin/UserDetails';
import { CreateClient } from './components/admin/CreateClient';
import { ClientDetails } from './components/admin/ClientDetails';
import { Admin } from './components/admin/Admin';
import { CreditApplicationStep1 } from './components/CreditApplicationStep1';
import { CreditApplicationStep2 } from './components/CreditApplicationStep2';
import { CreditApplicationStep3 } from './components/CreditApplicationStep3';
import { LoanApplicationStep1 } from './components/LoanApplicationStep1';
import { LoanApplicationStep2 } from './components/LoanApplicationStep2';
import { LoanApplicationStep3 } from './components/LoanApplicationStep3';
import { Reporting } from './components/Reporting';
import { CreditApplicationReceived } from './components/CreditApplicationReceived';
import { PersonalFinancialStatement } from './components/PersonalFinancialStatement';
import { SellerInformation } from './components/SellerInformation';
import { ApplicantPersonalFinancialStatement } from './components/ApplicantPersonalFinancialStatement';
import { LoanApplicationReceived } from './components/LoanApplicationReceived';
import { DocumentVault } from './components/DocumentVault';
import { Vessel } from './components/Vessel';
import { Lender } from './components/Lender';
import { ApplicantUpload } from './components/ApplicantUpload';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <AppBody>
                <AuthorizeRoute exact path='/' component={Home} />
                <Route path='/counter' component={Counter} />
                <AuthorizeRoute path='/fetch-data' component={FetchData} />
                <AuthorizeRoute exact path='/admin' component={Admin} />
                <AuthorizeRoute exact path='/admin/roles' component={Roles} />
                <AuthorizeRoute exact path='/admin/roles/create' component={CreateRole} />
                <AuthorizeRoute exact path='/admin/roles/details' component={RoleDetails} />
                <AuthorizeRoute exact path='/admin/users' component={Users} />
                <AuthorizeRoute exact path='/admin/users/details' component={UserDetails} />
                <AuthorizeRoute exact path='/admin/clients' component={Clients} />
                <AuthorizeRoute exact path='/admin/clients/create' component={CreateClient} />
                <AuthorizeRoute exact path='/admin/clients/details' component={ClientDetails} />
                <AuthorizeRoute exact path='/CreditApplicationStep1' component={CreditApplicationStep1} />
                <AuthorizeRoute exact path='/CreditApplicationStep2' component={CreditApplicationStep2} />
                <AuthorizeRoute exact path='/CreditApplicationStep3' component={CreditApplicationStep3} />
                <AuthorizeRoute exact path='/PersonalFinancialStatement' component={PersonalFinancialStatement} />
                <AuthorizeRoute exact path='/CreditApplicationReceived' component={CreditApplicationReceived} />
                <AuthorizeRoute exact path='/SellerInformation' component={SellerInformation} />
                <AuthorizeRoute exact path='/DocumentVault' component={DocumentVault} />
                <AuthorizeRoute exact path='/Vessel' component={Vessel} />
                <AuthorizeRoute exact path='/Lender' component={Lender} />
                <AuthorizeRoute exact path='/Reporting' component={Reporting} />
                <Route exact path='/accessdenied' component={AccessDenied} />
                <Route exact path='/LoanApplicationStep1' component={LoanApplicationStep1} />
                <Route exact path='/LoanApplicationStep2' component={LoanApplicationStep2} />
                <Route exact path='/LoanApplicationStep3' component={LoanApplicationStep3} />
                <Route exact path='/ApplicantPersonalFinancialStatement' component={ApplicantPersonalFinancialStatement} />
                <Route exact path='/LoanApplicationReceived' component={LoanApplicationReceived} />
                <Route exact path='/ApplicantUpload' component={ApplicantUpload} />
                <Route exact path='/accessdenied' component={AccessDenied} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </AppBody>
        );
    }
}
