import './TextInput.css';
import React, { Component, useState } from 'react';
import { useEffect } from 'react';

function TextInput({ Label, TextChanged, Value, Style, Type, MaxTextLength, MaxNumericValue, Disabled, DefaultValue, AutoFocus }) {
    const [isActive, setIsActive] = useState(false);
    //const { Label, TextChanged, Value, Style, Type, MaxTextLength, MaxNumericValue, Disabled, DefaultValue, OnBlur } = props;
    const [value, setValue] = useState('');
    const [className, setClassName] = useState('');
    const [classNameText, setClassNameText] = useState('');

    useEffect(function () {
        handleTextChange(Value);
    }, [{ Label, TextChanged, Value, Style, Type, MaxTextLength, MaxNumericValue, Disabled, DefaultValue }])

    function handleTextChange(text) {
        setValue(text);
        if (text !== '' && text != undefined) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }

    return (
        <div id="float-label" style={{ width: 'fit-content' }} >
            <input
                type={(Type === "custom" || Type === "ssn" || Type === "phone") ? "text" : Type === "date2" ? "date" : Type}
                style={Style}
                className={className}
                value={Value ?? DefaultValue ?? ""}
                maxLength={MaxTextLength ?? 9999}
                disabled={Disabled ?? false}
                max={Type == 'date' ? "9999-12-31" : Type == 'number' ? MaxNumericValue : ""}
                //defaultValue={DefaultValue ?? null}
                onInput={(e) => {
                    if (Type === "ssn") {
                        if (e.target.value !== undefined && e.target.value?.length !== 11 && e.target.value?.length !== 0) {
                            setClassName("red");
                            setClassNameText("red-text");
                        }
                        else {
                            setClassName("");
                            setClassNameText("");
                        }
                    }

                    if (Type === "phone") {
                        if (e.target.value !== undefined && e.target.value?.length !== 10 && e.target.value?.length !== 0) {
                            setClassName("red");
                            setClassNameText("red-text");
                        }
                        else {
                            setClassName("");
                            setClassNameText("");
                        }
                    }

                    handleTextChange(e.target.value);
                    TextChanged(e.target.value);
                }}

                onKeyPress={(event) => {
                    if (Type == "number" || Type == "custom") {
                        var alpha = /^\d*\.?\d*$/;
                        if (!alpha.test(event.key)) {
                            event.preventDefault();
                        }
                        else {
                            return true;
                        }
                    }
                }}
                onKeyDown={(e) => {
                    var val = e.target.value;
                    if (val == undefined) val = "";
                    var max = MaxTextLength;
                    if (max == undefined) max = 99999999;

                    if (Type == 'number' && val.length == max && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();

                    if (Type == "ssn" && e.key != "Backspace") {
                        if (val.length == 3) val = val + '-';
                        if (val.length == 6) val = val + '-';
                        TextChanged(val);
                    }
                }}
                //onKeyUp={(e) => {
                //    var val = e.target.value;
                //    if (Type == "ssn" && e.key != "Backspace") {
                //        if (val.length == 3) val = val + '-';
                //        if (val.length == 6) val = val + '-';
                //        TextChanged(val);
                //    }
                //}}
                onPaste={(event) => {
                    if (Type == "number" || Type == "custom") {
                        let pasteData = event.clipboardData.getData('Text');
                        if (pasteData) {
                            pasteData.replace(/[^0-9]*/g, '');
                        }
                    }
                }}
                onBlur={(e) => {
                    if (Type == "custom") {
                        if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                            var val = Value + '.00';
                            TextChanged(val);
                        }
                        else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                            var spl = e.target.value.split(".");
                            if (spl[1].length == 0) {
                                var val = Value + '00';
                                TextChanged(val);
                            }

                            if (spl[1].length == 1) {
                                var val = Value + '0';
                                TextChanged(val);
                            }
                        }
                    }
                    if (Type === "date2") {
                        setClassNameText("");
                    }
                }}
                onFocus={() => {
                    if (Type === "date2") {
                        setClassNameText("opacity-0");
                    }
                }}
                autoFocus={AutoFocus ? true : false}
            />

            <label className={isActive ? Type === "date2" ? "Active opacity-0" : "Active " + classNameText : "" + classNameText} htmlFor={Type} style={{ ...Style, fontFamily: 'inherit', fontSize: '12px' }}>
                {Label ?? ""}
            </label>
        </div>
    )
}

export default TextInput;
