const customFunctions = {
    keyAllowed: async function (keyCode) {
        var allowedKeys = [8, 37, 39, 9, 46];
        return allowedKeys.includes(keyCode);
    },
    // format a phone number as (xxx) xxx-xxxx
    formatPhoneNumber: function (phoneNumber) {
        if (phoneNumber == undefined) return "";

        var result;
        result = phoneNumber.replace(/[^0-9]/g, '');
        if (result.length == 10) {
            result = result.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }
        else {
            result = result.replace(/\//g, "");
        }

        return result;
    },
    formatSocialSecurity: function (val) {
        if (val == undefined) return "";

        val = val.replace(/\D/g, '');
        val = val.replace(/^(\d{3})/, '$1-');
        val = val.replace(/-(\d{2})/, '-$1-');
        val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
        return val;
    },
    formatDateOrTimeForInput: function (dateTime, dateOrTime) {

        var date = new Date(dateTime);

        var day = date.getDate(),
            month = date.getMonth() + 1,
            year = date.getFullYear(),
            hour = date.getHours(),
            min = date.getMinutes();

        month = (month < 10 ? "0" : "") + month;
        day = (day < 10 ? "0" : "") + day;
        hour = (hour < 10 ? "0" : "") + hour;
        min = (min < 10 ? "0" : "") + min;

        var formattedDate = "";
        var formattedTime = "";

        if (dateOrTime == "date") {
            if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
                formattedDate = year + "-" + month + "-" + day;
                return formattedDate;
            }
            else {
                return dateTime;
            }
        }
        else {
            if (!isNaN(hour) && !isNaN(min)) {
                formattedTime = hour + ":" + min;
                return formattedTime;
            }
            else {
                return dateTime;
            }
        }
    },
    isDate: function (dtValue2) {
        var pattern = /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)(\d{2})/
        var m = dtValue2.match(pattern);

        if (!m)
            return false;
        var d = new Date(dtValue2);

        if (d.getMonth() + 1 == parseInt(m[1], 10) && d.getDate() == parseInt(m[2], 10)) {
            return true;
        }
        return false;
    },
    formatCurrency: function (value) {
        if (value == undefined || value == 0) return "";
        var _value = value.toString().replace(/,/g, '').replace('$', '');

        if (_value.length > 0) {
            return new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }).format(_value);
        }
        else {
            return "";
        }
    },
    validateEmail: function (emailAddress) {
        var validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (emailAddress.match(validRegex)) {
            return true;
        } else {
            return false;
        }
    }
}

export { customFunctions };