import React, { Component } from 'react';
import { _fetch } from './_fetch';
import { Spinner } from 'reactstrap';
import TextInput from './TextInput';
import { customFunctions } from './CustomFunctions.js';
import Modal from 'react-modal';
import UploadPublic from "./upload-files-public.component";

export class LoanApplicationReceived extends Component {
    static displayName = LoanApplicationReceived.name;
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showUploadModal: false,
            autoRedirect: true,
            ContinueToken: ''
        }
    }

    componentDidMount() {
        var id = localStorage.getItem("CONTINUE_TOKEN");
        this.sendSalesRep(id);

        this.setState({ ContinueToken: id });

        setTimeout(() => {
            if (this.state.autoRedirect) {
                this.close();
            }
        }, 90000);
    }

    async printSave() {
        this.setState({ loading: true, autoRedirect: false });
        var tempfilename = "PersonalFinancialStatement.pdf";
        var filename = "";

        var f = _fetch();
        await fetch("/PdfPublic/GenerateLoanApplicationPDFPublic?id=" + this.state.ContinueToken, {
            method: 'GET',
            body: null,
            headers: { 'Content-Type': 'application/json' }
        })
            .then((res) => {
                const header = res.headers.get('Content-Disposition');
                const parts = header.split(';');
                if (parts[1]) {
                    filename = parts[1].split('=')[1];
                }
                else {
                    filename = tempfilename;
                }
                return res.blob();
            })
            .then(data => {
                f.downloadFile(data, filename, "application/pdf");
                this.setState({ loading: false, autoRedirect: false });
            })
            .catch((error) => {
                console.log('error in printSave - ' + error.message);
                this.setState({ loading: false });
            });

    }

    sendSalesRep(id) {
        var fetch = _fetch();

        var data = {
            Id: id,
            Subject: "New Loan Application Received",
            Body: "You have received a new loan application.  To retrieve and review your new application login at https://app.fairwindfinancial.com"
        };

        fetch.post("/email/sendsalesrepemail", data)
            .then((response) => {
                if (response) {

                }
            });
    }

    showUploadScreen() {
        this.setState({ autoRedirect: false, showUploadModal: true });
    }

    close() {
        sessionStorage.clear();
        //localStorage.clear();
        localStorage.removeItem("CONTINUE_TOKEN")
        window.location.href = 'https://fairwindfinancial.com/';
    }

    render() {
        return (
            <div className="container-top">
                <div className="container-for-fixed-header-center">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }
                    <table className="receivedTable">
                        <tbody>
                            <tr>
                                <td align="middle">
                                    <br />
                                    <b>Your application has been received!</b>
                                </td>
                            </tr>
                            <tr>
                                <td align="middle">
                                    <div style={{ textAlign: 'left' }}>
                                        Thank you for submitting your boat loan application.  A Fairwind Financial representative will be in touch with you shortly.
                                        If you would like to print or keep a copy of your loan application, you can do so via the Print/Save button below.
                                        If your loan request exceeds $150,000, income verification and other supporting documentation will be required inorder to fully process your application.
                                        Below is a list of the supporting documentation that may be required.  That information can be uploaded now via the "upload files" button below or you can provide it to your Fairwind's representative at a later time.
                                        If you do not have any documents to upload you can simply close this window or it will close automatically in 60 seconds.  Thank you and we look forward to working with you.<br /><br />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    <b>Supporting Documentation often required for loans exceeding $150,000…</b>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    <li>Copy of recent paystub for both borrowers</li>
                                    <li>Copy of your last two years of filed 1040 Personal Tax Returns (the first two pages and schedules 1, A, B, C, & E)</li>
                                    <li>If self employed, your last two years of filed Business Tax Returns may be required</li>
                                    <li>Copies of recent bank and/or investment account statements</li>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    <br />
                                    <b>Here are some other items that aid the boat loan process, which you can upload if you choose…</b>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    <li>Copy of each borrowers drivers license</li>
                                    <li>Copy of the signed purchase agreement</li>
                                    <li>Copy of the marine survey report</li>
                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    <br />
                                    If you have any questions regarding your application please contact us at...<br />
                                    Phone: 203-712-7204<br />
                                    Fax: 203-712-7236<br />
                                    Email: info@fairwindfinancial.com<br />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="footer-center-content">
                    <table>
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading}
                                        onClick={() => {
                                            this.printSave();
                                        }}>
                                        Print / Save
                                    </button>
                                </td>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading}
                                        onClick={() => {
                                            this.showUploadScreen();
                                        }}>
                                        Upload Files
                                    </button>
                                </td>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading}
                                        onClick={() => {
                                            this.close();
                                        }}>
                                        Close
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showUploadModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showUploadModal: false })}
                        className="et-modal"
                        contentLabel="Example Modal"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h5>Document Uploads</h5>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <UploadPublic CreditApplicationId={this.state.CreditApplicationId} ContinueToken={this.state.ContinueToken} />
                            <br />
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
                            <button
                                className="creditAppNextUpload"
                                style={{ whiteSpace: 'nowrap' }}
                                disabled={this.state.loading}
                                onClick={() => {
                                    this.setState({ showUploadModal: false });
                                }}>
                                Close
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}