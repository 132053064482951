const FileHelper = {
    DownloadFile: function (data, filename, fileType) {
        const url = window.URL.createObjectURL(new Blob([data], { type: fileType }))
        const a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    }
}

export default FileHelper;