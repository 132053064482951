import authService from './api-authorization/AuthorizeService';

export { _fetch };

function _fetch() {
    return {
        get: request('GET'),
        post: request('POST'),
        postBlob: request('POST', undefined, true),
        put: request('PUT'),
        delete: request('DELETE'),
        fetchValue: request("GET", "text/plain"),
        postValue: request("POST", "text/plain"),
        downloadFile(data, filename, fileType) {
            const url = window.URL.createObjectURL(new Blob([data], { type: fileType }))
            const a = document.createElement("a");
            a.style = "display: none";
            document.body.appendChild(a);
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    };

    function request(method, contentType, isBlob) {
        try {
            return async (url, body) => {
                const token = await authService.getAccessToken();
                const response = await fetch(url, {
                    method: method,
                    headers: !token ? {
                        'Content-Type': contentType == undefined ? 'application/json' : contentType,
                        'Accept': "application/json, text/plain, */*"
                    } :
                        {
                            'Content-Type': contentType == undefined ? 'application/json' : contentType,
                            'Accept': "application/json, text/plain, */*",
                            'Authorization': `Bearer ${token}`
                        },
                    body: body == undefined ? null : JSON.stringify(body)
                });
                if (!response.ok) {
                    if ([401, 403].includes(response.status)) {
                        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                        //await authService.userManager.removeUser()
                        //    .then(async () => authService.completeSignOut('/'));
                    }

                    const error = response.statusText;
                    return Promise.reject(error);
                }
                else {
                    const data = contentType == undefined && isBlob == undefined ? await response.json() : isBlob == undefined ? await response.text() : await response;
                    return data;
                }
            }
        }
        catch (err) {
            console.log("Error in _fetch.request - " + err);
        }
    }
}