import React, { Component } from "react";
import UploadService from "../services/upload.applicant.service";
import './layout/site.css';
import { IoTrashOutline } from 'react-icons/io5';
import Modal from 'react-modal';

export default class UploadApplicantFiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: undefined,
            progressInfos: [],
            message: [],
            fileInfos: [],
            trashColor: '#000',
            documentTypes: [],
            DocumentTypeId: 0
        };

        this.selectFile = this.selectFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.fileInputRef = React.createRef()
    }

    componentDidMount() {
        UploadService.getDocumentTypes()
            .then((response) => {
                this.setState({
                    documentTypes: response.data,
                }, function () {
                    this.loadUploadFiles();
                });
            });
    }

    loadUploadFiles() {
        UploadService.getFiles(this.props.ContinueToken).then((response) => {
            this.setState({
                fileInfos: response.data
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.ContinueToken !== this.props.ContinueToken) {
            UploadService.getFiles(this.props.ContinueToken).then((response) => {
                this.setState({
                    fileInfos: response.data,
                    selectedFiles: undefined,
                    DocumentTypeId: 0
                });
            });
        }
    }

    selectFile(event) {
        this.setState({
            progressInfos: [],
            selectedFiles: event.target.files,
        });
    }

    uploadFiles() {
        const selectedFiles = this.state.selectedFiles;
        const documentTypeId = this.state.DocumentTypeId;

        let _progressInfos = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
        }
        this.setState(
            {
                progressInfos: _progressInfos,
                message: [],
            },
            () => {
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.upload(i, selectedFiles[i], documentTypeId, this.props.ContinueToken, this.state.Notes);
                }
            }
        );
    }

    upload(idx, file, documentTypeId, continueToken, notes) {
        let _progressInfos = [...this.state.progressInfos];
        UploadService.upload(file, documentTypeId, continueToken, notes, (event) => {
            _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
            this.setState({
                _progressInfos,
            });
        })
            .then((response) => {
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Uploaded the file successfully: " + file.name];
                    return {
                        message: nextMessage
                    };
                });
                return UploadService.getFiles(continueToken);

            })
            .then((files) => {
                this.setState({
                    fileInfos: files.data,
                });
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Could not upload the file: " + file.name];
                    return {
                        progressInfos: _progressInfos,
                        message: nextMessage
                    };
                });
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({
                        selectedFiles: undefined,
                        progressInfos: [],
                        message: [],
                        DocumentTypeId: 0,
                        Notes: ''
                    }, function () {
                        this.fileInputRef.current.value = ""
                    });
                }, 5000);
            });
    }

    deleteUpload(id) {
        this.setState({ uploadId: id, showModal: true });
    }

    async deleteUploadedDocument() {
        this.setState({ showModal: false });
        //const token = await authService.getAccessToken();
        var id = this.state.uploadId;

        var response = await fetch('uploadpublic/DeleteUploadFilePublic?id=' + id, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                UploadService.getFiles(this.props.ContinueToken).then((files) => {
                    this.setState({
                        fileInfos: files.data,
                        DocumentTypeId: 0
                    });
                });
            });
    }

    render() {
        const { selectedFiles, progressInfos, message, fileInfos, documentTypes } = this.state;
        return (
            <div>
                <table className="docVaultTable">
                    <tbody>
                        <tr>
                            <td>
                                Document Type
                            </td>
                            <td>
                                <select
                                    value={this.state.DocumentTypeId}
                                    onChange={(e) => {
                                        this.setState({ DocumentTypeId: e.target.value });
                                    }}
                                    style={{ width: '288px' }}
                                >
                                    <option key={0} value={0}>Select Document Type</option>
                                    {documentTypes && documentTypes.map((item, idx) =>
                                        <option key={idx} value={item.id}>{item.name}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">
                                Notes
                            </td>
                            <td>
                                <textarea
                                    style={{ width: '288px', resize: 'none' }}
                                    placeholder=""
                                    onChange={(e) => {
                                        this.setState({ Notes: e.target.value })
                                    }}
                                    value={this.state.Notes}
                                ></textarea>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2}>
                                                {progressInfos &&
                                                    progressInfos.map((progressInfo, index) => (
                                                        <div className="mb-2" key={index}>
                                                            <span>{progressInfo.fileName}</span>
                                                            <div className="progress">
                                                                <div
                                                                    className="progress-bar progress-bar-info"
                                                                    role="progressbar"
                                                                    aria-valuenow={progressInfo.percentage}
                                                                    aria-valuemin="0"
                                                                    aria-valuemax="100"
                                                                    style={{ width: progressInfo.percentage + "%" }}
                                                                >
                                                                    {progressInfo.percentage}%
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <label className="uploadLabel">
                                                    <input ref={this.fileInputRef} type="file" accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt,.csv,.ppt,.pptx,.odt,.ods"
                                                        onChange={this.selectFile} />
                                                </label>
                                            </td>
                                            <td>
                                                <button
                                                    className="creditAppNextUpload"
                                                    disabled={!selectedFiles || this.state.DocumentTypeId === 0}
                                                    onClick={this.uploadFiles}
                                                >
                                                    Upload
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {message.length > 0 && (
                    <div className="alert alert-secondary" role="alert">
                        <ul>
                            {message.map((item, i) => {
                                return <li key={i}>{item}</li>;
                            })}
                        </ul>
                    </div>
                )}
                <hr />
                <div className="card-student">
                    <div className="card-header">Your Documents</div>
                    <table className="gridTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    File Name
                                </th>
                                <th>
                                    Document Type
                                </th>
                                <th>
                                    Notes
                                </th>
                                <th>
                                    Date/Time Uploaded
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.fileInfos.length > 0 ?
                                    fileInfos && fileInfos.map((file, index) =>

                                        <tr key={index}>
                                            <td>
                                                <img src={file.iconUrl} style={{ width: '21px' }} />
                                            </td>
                                            <td>
                                                {file.filename}
                                            </td>
                                            <td>
                                                {file.dtName}
                                            </td>
                                            <td>
                                                {file.notes}
                                            </td>
                                            <td>
                                                {file.dateUploaded}
                                            </td>
                                            <td className="deleteUploadCell">
                                                <IoTrashOutline
                                                    color={this.state.trashColor}
                                                    size={21}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => this.deleteUpload(file.id)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={6} style={{ fontWeight: '500', textAlign: 'center' }}>
                                            No Uploads Found
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showModal: false })}
                        className="et-modal"
                        contentLabel="Delete Upload"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h6>Delete the uploaded document?</h6>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '15px' }}>
                            <button
                                className="creditAppNextUpload"
                                onClick={() => this.setState({ showModal: false })}
                            >
                                No
                            </button>
                            <button
                                className="creditAppNextUpload"
                                onClick={() => this.deleteUploadedDocument()}
                            >
                                Yes
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}