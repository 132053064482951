import React, { Component } from 'react';
import { Container, Row, Col, Table, Button } from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import AdminFunctions from './AdminFunctions';
import { FaSort } from 'react-icons/fa'
import '../layout/site.css';

export class Roles extends Component {
    static displayName = Roles.name;

    constructor(props) {
        super(props);

        this.state = {
            roles: [],
            sort: {
                column: null,
                direction: 'desc'
            },
        };

    }

    async componentDidMount() {        
        const isAdmin = await AdminFunctions.isAdminAsync();
        if(!isAdmin) {
            this.props.history.push('/accessdenied');
        }
        this.getRoles();
    }

    render() {
        return (
            <div>
                <Container className='container-main'>
                    <Row>
                        <Col xl='10' lg='10' md='10' sm='10' xs='10'>
                            <h3>Roles</h3>
                        </Col>
                        <Col xl='2' lg='2' md='2' sm='2' xs='2'>
                            <Button
                                color='primary'
                                onClick={() => this.props.history.push('/admin/roles/create')}
                                style={{ whiteSpace: 'nowrap' }}
                            >
                                + Add
                            </Button>
                        </Col>
                    </Row>
                    <Row className='row-20'>
                        <Col>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th>
                                            Id
                                            <FaSort
                                                color={this.state.sort.column === 'id' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('id')}
                                                className='sort-button'
                                            />
                                        </th>
                                        <th>
                                            Name
                                            <FaSort
                                                color={this.state.sort.column === 'name' ? '#0a58ca' : 'black'}
                                                onClick={() => this.handleSort('name')}
                                                className='sort-button'
                                            />
                                        </th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.roles.map((role, i) => {
                                        return (
                                            <tr
                                                key={role.id}
                                                onClick={() => this.handleRowClick(role.id)}
                                            >
                                                <td>{role.id}</td>
                                                <td>{role.name}</td>                                                
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>                    
                </Container>
            </div>
        )
    }

    async getRoles() {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Admin/GetRolesAsync`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ roles: data });
    }    

    handleRowClick(roleId) {
        this.props.history.push({
            pathname: '/admin/roles/details',
            state: { roleId: roleId, existingRole: true }
        });
    }

    // table sort

    handleSort(column) {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const data = this.state.roles;
        data.sort((a, b) => a[column].localeCompare(b[column]))
        if(direction === 'desc') {
            data.reverse();
        }
        this.setState({
            roles : data,
            sort: {
                column,
                direction
            }
        })
    };

}