import React, { Component } from "react";
import UploadService from "../services/upload-files.service";
import authService from './api-authorization/AuthorizeService';
import './layout/site.css';
import { IoTrashOutline } from 'react-icons/io5';
import Modal from 'react-modal';

export default class UploadFiles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFiles: undefined,
            progressInfos: [],
            message: [],
            fileInfos: [],
            trashColor: '#000',
            documentTypes: [],
            DocumentTypeId: 0
        };

        this.selectFile = this.selectFile.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.fileInputRef = React.createRef()
    }

    componentDidMount() {
        UploadService.getDocumentTypes()
            .then((response) => {
                this.setState({
                    documentTypes: response.data,
                }, function () {
                    this.loadUploadFiles();
                });
            });
    }

    loadUploadFiles() {
        UploadService.getFiles(this.props.CreditApplicationId).then((response) => {
            this.setState({
                fileInfos: response.data
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.CreditApplicationId !== this.props.CreditApplicationId) {
            UploadService.getFiles(this.props.CreditApplicationId).then((response) => {
                this.setState({
                    fileInfos: response.data,
                    selectedFiles: undefined,
                    DocumentTypeId: 0
                });
            });
        }
    }

    selectFile(event) {
        this.setState({
            progressInfos: [],
            selectedFiles: event.target.files,
        });
    }

    uploadFiles() {
        const selectedFiles = this.state.selectedFiles;
        const documentTypeId = this.state.DocumentTypeId;

        let _progressInfos = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
        }
        this.setState(
            {
                progressInfos: _progressInfos,
                message: [],
            },
            () => {
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.upload(i, selectedFiles[i], this.props.CreditApplicationId, documentTypeId);
                }
            }
        );
    }

    upload(idx, file, creditApplicationId, documentTypeId) {
        let _progressInfos = [...this.state.progressInfos];
        UploadService.upload(file, creditApplicationId, documentTypeId, (event) => {
            _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
            this.setState({
                _progressInfos,
            });
        })
            .then((response) => {
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Uploaded the file successfully: " + file.name];
                    return {
                        message: nextMessage
                    };
                });
                return UploadService.getFiles(creditApplicationId);

            })
            .then((files) => {
                this.setState({
                    fileInfos: files.data,
                });
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                this.setState((prev) => {
                    let nextMessage = [...prev.message, "Could not upload the file: " + file.name];
                    return {
                        progressInfos: _progressInfos,
                        message: nextMessage
                    };
                });
            })
            .finally(() => {
                setTimeout(() => {
                    this.setState({
                        selectedFiles: undefined,
                        progressInfos: [],
                        message: [],
                        DocumentTypeId: 0
                    }, function () {
                        this.fileInputRef.current.value = ""
                    });
                }, 5000);
            });
    }

    deleteUpload(id) {
        this.setState({ uploadId: id, showModal: true });
    }

    async deleteUploadedDocument() {
        this.setState({ showModal: false });
        //const token = await authService.getAccessToken();
        var id = this.state.uploadId;

        var response = await fetch('upload/DeleteUploadFile?id=' + id, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => {
                UploadService.getFiles(this.props.CreditApplicationId).then((files) => {
                    this.setState({
                        fileInfos: files.data,
                        DocumentTypeId: 0
                    });
                });
            });
    }

    render() {
        const { selectedFiles, progressInfos, message, fileInfos, documentTypes } = this.state;
        return (
            <div>
                {progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                        <div className="mb-2" key={index}>
                            <span>{progressInfo.fileName}</span>
                            <div className="progress">
                                <div
                                    className="progress-bar progress-bar-info"
                                    role="progressbar"
                                    aria-valuenow={progressInfo.percentage}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style={{ width: progressInfo.percentage + "%" }}
                                >
                                    {progressInfo.percentage}%
                                </div>
                            </div>
                        </div>
                    ))}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '2vh', marginTop: '2vh' }}>
                    <label style={{ marginRight: '5px' }}>Document Type</label>
                    <select
                        value={this.state.DocumentTypeId}
                        onChange={(e) => {
                            this.setState({ DocumentTypeId: e.target.value });
                        }}
                    >
                        <option key={0} value={0}>Select Document Type</option>
                        {documentTypes && documentTypes.map((item, idx) =>
                            <option key={idx} value={item.id}>{item.name}</option>
                        )}
                    </select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: '2vh', marginTop: '2vh' }}>
                    <label className="uploadLabel">
                        <input ref={this.fileInputRef} type="file" accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.txt,.csv,.ppt,.pptx,.odt,.ods"
                            onChange={this.selectFile} />
                    </label>
                    <button
                        className="creditAppNextUpload"
                        disabled={!selectedFiles || this.state.DocumentTypeId === 0}
                        onClick={this.uploadFiles}
                    >
                        Upload
                    </button>
                </div>
                {message.length > 0 && (
                    <div className="alert alert-secondary" role="alert">
                        <ul>
                            {message.map((item, i) => {
                                return <li key={i}>{item}</li>;
                            })}
                        </ul>
                    </div>
                )}
                <div className="card-student">
                    <div className="card-header">Your Documents</div>
                    <table className="gridTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>
                                    File Name
                                </th>
                                <th>
                                    Document Type
                                </th>
                                <th>
                                    Date/Time Uploaded
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.fileInfos.length > 0 ?
                                    fileInfos && fileInfos.map((file, index) =>

                                        <tr key={index}>
                                            <td>
                                                <img src={file.iconUrl} style={{ width: '21px' }} />
                                            </td>
                                            <td>
                                                {file.filename}
                                            </td>
                                            <td>
                                                {file.dtName}
                                            </td>
                                            <td>
                                                {file.dateUploaded}
                                            </td>
                                            <td className="deleteUploadCell">
                                                <IoTrashOutline
                                                    color={this.state.trashColor}
                                                    size={21}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => this.deleteUpload(file.id)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={5} style={{ fontWeight: '500', textAlign: 'center' }}>
                                            No Uploads Found
                                        </td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.showModal}
                        //onAfterOpen={ }
                        onRequestClose={() => this.setState({ showModal: false })}
                        className="et-modal"
                        contentLabel="Delete Upload"
                        overlayClassName="Overlay"
                    >
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <h6>Delete the uploaded document?</h6>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', marginTop: '15px' }}>
                            <button
                                className="creditAppNextUpload"
                                onClick={() => this.setState({ showModal: false })}
                            >
                                No
                            </button>
                            <button
                                className="creditAppNextUpload"
                                onClick={() => this.deleteUploadedDocument()}
                            >
                                Yes
                            </button>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}