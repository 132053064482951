import http from "../http-common";
import authService from '../components/api-authorization/AuthorizeService';

class UploadFilesService {
    async upload(file, creditApplicationId, documentTypeId, onUploadProgress) {
        const token = await authService.getAccessToken();
        let formData = new FormData();
        formData.append("file", file);
        formData.append("creditApplicationId", creditApplicationId);
        formData.append("documentTypeId", documentTypeId);
        return http.post("upload/uploadfile", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            },
            onUploadProgress,
        });
    }
    async getFiles(creditApplicationId) {
        const token = await authService.getAccessToken();
        return http.get("upload/getfiles?creditApplicationId=" + creditApplicationId, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }

    async getDocumentTypes() {
        const token = await authService.getAccessToken();
        return http.get("upload/GetDocumentTypes", {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }
}
export default new UploadFilesService();