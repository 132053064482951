import React, { Component } from 'react';
import { _fetch } from './_fetch';
import TextInput from './TextInput';
import { Spinner } from 'reactstrap';
import Toast from './toast.js';
import { customFunctions } from './CustomFunctions.js';
import { format } from 'date-fns';

export class ApplicantPersonalFinancialStatement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            ContinueToken: ''
        };
    }

    componentDidMount() {
        try {
            this.setState({ loading: true });

            var id = localStorage.getItem("CONTINUE_TOKEN");

            if (id != null) this.setState({ ContinueToken: id });

            if (id != null) this.loadPage(id);

            var borrowerSig = sessionStorage.getItem("BorrowerSignature");
            var coBorrowerSig = sessionStorage.getItem("CoBorrowerSignature");
            this.setState({ loading: false, BorrowerSignatureName: borrowerSig, CoBorrowerSignatureName: coBorrowerSig });
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('componentDidMount - ' + err);
        }
    }

    calculateNetWorth() {
        let s = this.state;
        var assets = this.zeroOrValue(s.BoatDepositsPaidToDateValue) + this.zeroOrValue(s.CheckingValue) + this.zeroOrValue(s.SavingsValue) + this.zeroOrValue(s.Ira401KValue) +
            this.zeroOrValue(s.StockBondsValue) + this.zeroOrValue(s.MutualFundValue) + this.zeroOrValue(s.OtherValue) + this.zeroOrValue(s.TrustFundValue) +
            this.zeroOrValue(s.LifeInsuranceCashValue) + this.zeroOrValue(s.PrimaryResidenceValue) + this.zeroOrValue(s.SecondaryResidenceValue) +
            this.zeroOrValue(s.OtherResidenceValue) + this.zeroOrValue(s.RentalValue1) + this.zeroOrValue(s.RentalValue2) + this.zeroOrValue(s.RentalValue3) +
            this.zeroOrValue(s.RealEstateOtherValue1) + this.zeroOrValue(s.RealEstateOtherValue2) + this.zeroOrValue(s.BusinessAssetPercentValue1) +
            this.zeroOrValue(s.BusinessAssetPercentValue2) + this.zeroOrValue(s.BusinessAssetPercentValue3) +
            this.zeroOrValue(s.BusinessAssetPercentValue4) + this.zeroOrValue(s.OtherAssetCurrentBoatValue) + this.zeroOrValue(s.OtherAsset2ndBoatValue) +
            this.zeroOrValue(s.OtherAssetRVAircraftValue) + this.zeroOrValue(s.OtherAssetAutomobilesValue) + this.zeroOrValue(s.OtherAssetArtJeweleryValue) +
            this.zeroOrValue(s.OtherAssetOtherValue);

        var liabilities = this.zeroOrValue(s.AutoLoanCurrentBalance1) + this.zeroOrValue(s.AutoLoanCurrentBalance2) + this.zeroOrValue(s.AutoLoanCurrentBalance3) +
            this.zeroOrValue(s.StudentLoanCurrentBalance) + this.zeroOrValue(s.UnsecuredLoanCurrentBalance) + this.zeroOrValue(s.CreditCardsCurrentBalance) +
            this.zeroOrValue(s.LineOfCreditCurrentBalance) + this.zeroOrValue(s.OtherCurrentBalance1) + this.zeroOrValue(s.OtherCurrentBalance2) +
            this.zeroOrValue(s.OtherCurrentBalance3) + this.zeroOrValue(s.PrimaryResidenceCurrentBalance) + this.zeroOrValue(s.SecondaryResidenceCurrentBalance) +
            this.zeroOrValue(s.OtherResidenceCurrentBalance) + this.zeroOrValue(s.RentalBalance1) + this.zeroOrValue(s.RentalBalance2) +
            this.zeroOrValue(s.RentalBalance3) + this.zeroOrValue(s.RealEstateOtherBalance1) + this.zeroOrValue(s.RealEstateOtherBalance2) +
            this.zeroOrValue(s.BusinessAssetBusinessCurrentBalance1) + this.zeroOrValue(s.BusinessAssetBusinessCurrentBalance2) + this.zeroOrValue(s.BusinessAssetBusinessCurrentBalance3) +
            this.zeroOrValue(s.BusinessAssetBusinessCurrentBalance4) + this.zeroOrValue(s.OtherSecuredLoansBoatCurrentBalance1) + this.zeroOrValue(s.OtherSecuredLoansBoatCurrentBalance2) +
            this.zeroOrValue(s.OtherSecuredLoansRVAircraftCurrentBalance) + this.zeroOrValue(s.OtherSecuredLoansOtherCurrentBalance1) + this.zeroOrValue(s.OtherSecuredLoansOtherCurrentBalance2) +
            this.zeroOrValue(s.OtherSecuredLoansOtherCurrentBalance3) + this.zeroOrValue(s.OtherSecuredLoansOtherCurrentBalance4);

        var netWorth = (assets - liabilities);

        var assetsRaw = this.formatCurrency(assets);

        if (assetsRaw && assetsRaw.startsWith('$') && !assetsRaw.includes('.')) {
            assetsRaw = assetsRaw + '.00';
        }
        else if (assetsRaw.startsWith('$') && assetsRaw.includes('.')) {
            var spl = assetsRaw.split(".");
            if (spl[1].length == 0) {
                assetsRaw = assetsRaw + '00';
            }

            if (spl[1].length == 1) {
                assetsRaw = assetsRaw + '0';
            }
        }

        var liabilitiesRaw = this.formatCurrency(liabilities);

        if (liabilitiesRaw && liabilitiesRaw.startsWith('$') && !liabilitiesRaw.includes('.')) {
            liabilitiesRaw = liabilitiesRaw + '.00';
        }
        else if (liabilitiesRaw.startsWith('$') && liabilitiesRaw.includes('.')) {
            var spl = liabilitiesRaw.split(".");
            if (spl[1].length == 0) {
                liabilitiesRaw = liabilitiesRaw + '00';
            }

            if (spl[1].length == 1) {
                liabilitiesRaw = liabilitiesRaw + '0';
            }
        }

        var netWorthRaw = this.formatCurrency(netWorth);

        if (netWorthRaw && netWorthRaw.startsWith('$') && !netWorthRaw.includes('.')) {
            netWorthRaw = netWorthRaw + '.00';
        }
        else if (netWorthRaw.startsWith('$') && netWorthRaw.includes('.')) {
            var spl = netWorthRaw.split(".");
            if (spl[1].length == 0) {
                netWorthRaw = netWorthRaw + '00';
            }

            if (spl[1].length == 1) {
                netWorthRaw = netWorthRaw + '0';
            }
        }

        this.setState({
            TotalAssets: assetsRaw,
            TotalLiabilities: liabilitiesRaw,
            NetWorth: netWorthRaw
        });
    }

    zeroOrValue(value) {
        if (value == undefined || value == 0) return 0;
        //if (isNaN(value)) {
        //    value = value.toString().substring(1, value.toString().lenth - 1);
        //}
        var _value = value.replace(/,/g, '').replace('$', '');

        if (_value.length > 0) {

            var result = (isNaN(_value) || _value == undefined) ? 0 : parseInt(_value);
            return result;
        }
        else {
            return 0;
        }
    }

    formatCurrency(value) {
        if (value == undefined) return "";
        var _values = value.toString().replace(/,/g, '').replace('$', '');

        var dec = "";
        var _value = "";

        var elements = _values.split('.');
        if (elements?.length == 1) {
            _value = _values.split('.')[0];
        }
        else if (elements?.length > 1) {
            _value = _values.split('.')[0];
            dec = "." + _values.split('.')[1].substring(0, 2);
        }
        else {
            _value = _values;
        }
        if (_value.length > 0) {
            return new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }).format(_value) + dec;
        }
    }

    async save(back) {
        try {
            var item = [];
            if (back === undefined) {
                if (localStorage.getItem("APP_TYPE") == "Individual") {
                    if (this.state.BorrowerSignature == undefined || this.state.BorrowerSignature?.length == 0) {
                        item.push("Borrower Signature");
                    }
                    if (this.state.BorrowerSignatureName == undefined || this.state.BorrowerSignatureName?.length == 0) {
                        item.push("Borrower Signature Name");
                    }
                    if (this.state.BorrowerSignatureDate == undefined || this.state.BorrowerSignatureDate?.length == 0) {
                        item.push("Borrower Signature Date");
                    }
                }

                if (localStorage.getItem("APP_TYPE") == "Jointly") {
                    if (this.state.BorrowerSignature == undefined || this.state.BorrowerSignature?.length == 0) {
                        item.push("Borrower Signature");
                    }
                    if (this.state.BorrowerSignatureName == undefined || this.state.BorrowerSignatureName?.length == 0) {
                        item.push("Borrower Signature Name");
                    }
                    if (this.state.BorrowerSignatureDate == undefined || this.state.BorrowerSignatureDate?.length == 0) {
                        item.push("Borrower Signature Date");
                    }
                    if (this.state.CoBorrowerSignature == undefined || this.state.CoBorrowerSignature?.length == 0) {
                        item.push("CoBorrower Signature");
                    }
                    if (this.state.CoBorrowerSignatureName == undefined || this.state.CoBorrowerSignatureName?.length == 0) {
                        item.push("CoBorrower Signature Name");
                    }
                    if (this.state.CoBorrowerSignatureDate == undefined || this.state.CoBorrowerSignatureDate?.length == 0) {
                        item.push("CoBorrower Signature Date");
                    }
                }
            }

            if (item.length == 0) {
                this.setState({ loading: true });
                var fetch = _fetch();
                this.setState({
                    BorrowerSignatureDate: this.state.BorrowerSignatureDate === undefined || this.state.BorrowerSignatureDate == "" ? null : format(new Date(this.state.BorrowerSignatureDate.replace(/-/g, '/')), "yyyy-MM-dd"),
                    CoBorrowerSignatureDate: this.state.CoBorrowerSignatureDate === undefined || this.state.CoBorrowerSignatureDate == "" ? null : format(new Date(this.state.CoBorrowerSignatureDate.replace(/-/g, '/')), "yyyy-MM-dd")
                },
                    function () {
                        fetch.post("/creditapplicationpublic/SavePersonalFinancialStatementPublic", this.state)
                            .then((id) => {
                                if (id) {
                                    if (back === 1) {
                                        if (localStorage.getItem("APP_TYPE") != null && localStorage.getItem("APP_TYPE") == "Individual") {
                                            window.location.href = "/LoanApplicationStep2";
                                        }
                                        else {
                                            window.location.href = "/LoanApplicationStep3";
                                        }
                                    }
                                    else {
                                        this.setState({ loading: false });
                                        window.location.href = '/LoanApplicationReceived';
                                    }
                                }
                                else {
                                    this.setState({
                                        showToast: true,
                                        toastMessage: 'There was an issue saving your information. Please try again',
                                        loading: false
                                    });
                                }
                            })
                    });
            }
            else {
                this.setState({
                    showToast: true,
                    toastMessage: 'The following fields are required:',
                    toastItems: item
                });
                setTimeout(function () { document.getElementById("maincontainer").scrollTo({ top: 0, behavior: 'smooth' }); }, 250);
            }
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('save - ' + err);
        }
    }

    async loadPage(id) {
        try {
            this.setState({ loading: true });
            var fetch = _fetch();

            fetch.get("/creditapplicationpublic/GetPersonalFinancialStatementPublic?id=" + id)
                .then((data) => {
                    var _state = this.state;
                    for (const key in data) {
                        if (data[key] != null) {
                            var _key = key.charAt(0).toUpperCase() + key.slice(1);
                            var _value = data[key];
                            _state[_key] = _value;
                        }
                    }
                    this.setState(_state, function () {
                        this.calculateNetWorth();
                        this.setState({ loading: false });
                        this.setState({
                            BorrowerSignatureDate: this.sqlToJsDate(this.state.BorrowerSignatureDate),
                            CoBorrowerSignatureDate: this.sqlToJsDate(this.state.CoBorrowerSignatureDate)
                        });
                    });
                })
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('save - ' + err);
        }
    }

    sqlToJsDate(sqlDate) {
        if (sqlDate == "" || sqlDate === undefined) return sqlDate;

        var dateParts = sqlDate.split("T");
        return dateParts[0];
    }

    render() {
        return (
            <div className="container-top">
                <div id="maincontainer" className="container-for-fixed-header">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }
                    <Toast
                        toastText={this.state.toastMessage}
                        listItems={this.state.toastItems}
                        showToast={this.state.showToast}
                        onToastClose={() => this.setState({ showToast: false })}
                        autoHideToastDelay={0}
                    />
                    <table className="personalFinancialStatementTable">
                        <tbody>
                            <tr>
                                <td colSpan={8} align="center" valign="middle">
                                    <label style={{ width: 'fit-content', paddingTop: '10px', paddingBottom: '15px', fontSize: '19px', color: '#596db3', textAlign: 'center', marginBottom: '0' }}>Personal Financial Statement</label>
                                </td>
                            </tr>
                            <tr>
                                <td>Assets</td>
                                <td colSpan={2}></td>
                                <td></td>
                                <td>Liabilities</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Cash Assets</td>
                                <td colSpan={2}>Institution</td>
                                <td>Value ($)</td>
                                <td>Liability Type</td>
                                <td>Institution</td>
                                <td>Current Balance</td>
                                <td>Mo. Pmt. ($)</td>
                            </tr>
                            <tr>
                                <td>
                                    Boat Deposit, Paid to Date
                                </td>
                                <td colSpan={2}>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BoatDepositsPaidToDateInstitution: e.target.value });
                                        }}
                                        value={this.state.BoatDepositsPaidToDateInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BoatDepositsPaidToDateValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.BoatDepositsPaidToDateValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BoatDepositsPaidToDateValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BoatDepositsPaidToDateValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>Auto Loan</td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ AutoLoanInstitution1: e.target.value });
                                        }}
                                        value={this.state.AutoLoanInstitution1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ AutoLoanCurrentBalance1: e.target.value });
                                            this.calculateNetWorth();
                                        }}
                                        value={this.formatCurrency(this.state.AutoLoanCurrentBalance1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.AutoLoanCurrentBalance1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                AutoLoanCurrentBalance1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ AutoLoanMonthlyPayment1: e.target.value }, function () {
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.AutoLoanMonthlyPayment1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.AutoLoanMonthlyPayment1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                AutoLoanMonthlyPayment1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Checking Accts
                                </td>
                                <td colSpan={2}>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ CheckingInstitution: e.target.value });
                                        }}
                                        value={this.state.CheckingInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ CheckingValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.CheckingValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.CheckingValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                CheckingValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>Auto Loan</td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ AutoLoanInstitution2: e.target.value });
                                        }}
                                        value={this.state.AutoLoanInstitution2}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ AutoLoanCurrentBalance2: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.AutoLoanCurrentBalance2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.AutoLoanCurrentBalance2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                AutoLoanCurrentBalance2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ AutoLoanMonthlyPayment2: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.AutoLoanMonthlyPayment2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.AutoLoanMonthlyPayment2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                AutoLoanMonthlyPayment2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Savings & Money Market
                                </td>
                                <td colSpan={2}>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ SavingsInstitution: e.target.value });
                                        }}
                                        value={this.state.SavingsInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ SavingsValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.SavingsValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.SavingsValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                SavingsValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>Auto Loan</td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ AutoLoanInstitution3: e.target.value });
                                        }}
                                        value={this.state.AutoLoanInstitution3}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ AutoLoanCurrentBalance3: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.AutoLoanCurrentBalance3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.AutoLoanCurrentBalance3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                AutoLoanCurrentBalance3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ AutoLoanMonthlyPayment3: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.AutoLoanMonthlyPayment3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.AutoLoanMonthlyPayment3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                AutoLoanMonthlyPayment3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    401K / IRA
                                </td>
                                <td colSpan={2}>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ Ira401KInstitution: e.target.value });
                                        }}
                                        value={this.state.Ira401KInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ Ira401KValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.Ira401KValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.Ira401KValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                Ira401KValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>Student Loans</td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ StudentLoanInstitution: e.target.value });
                                        }}
                                        value={this.state.StudentLoanInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ StudentLoanCurrentBalance: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.StudentLoanCurrentBalance)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.StudentLoanCurrentBalance;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                StudentLoanCurrentBalance: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ StudentLoanMonthlyPayment: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.StudentLoanMonthlyPayment)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.StudentLoanMonthlyPayment;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                StudentLoanMonthlyPayment: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Stock/Bond/Mutual Funds
                                </td>
                                <td colSpan={2}>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ StockBondsInstitution: e.target.value });
                                        }}
                                        value={this.state.StockBondsInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ StockBondsValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.StockBondsValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.StockBondsValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                StockBondsValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>Unsecured Loans</td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ UnsecuredLoanInstitution: e.target.value });
                                        }}
                                        value={this.state.UnsecuredLoanInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ UnsecuredLoanCurrentBalance: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.UnsecuredLoanCurrentBalance)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.UnsecuredLoanCurrentBalance;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                UnsecuredLoanCurrentBalance: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ UnsecuredLoanMonthlyPayment: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.UnsecuredLoanMonthlyPayment)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.UnsecuredLoanMonthlyPayment;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                UnsecuredLoanMonthlyPayment: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other
                                </td>
                                <td colSpan={2}>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherInstitution: e.target.value });
                                        }}
                                        value={this.state.OtherInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>Credit Cards</td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ CreditCardsInstitution: e.target.value });
                                        }}
                                        value={this.state.CreditCardsInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ CreditCardsCurrentBalance: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.CreditCardsCurrentBalance)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.CreditCardsCurrentBalance;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                CreditCardsCurrentBalance: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ CreditCardsMonthlyPayment: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.CreditCardsMonthlyPayment)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.CreditCardsMonthlyPayment;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                CreditCardsMonthlyPayment: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Trust Accts
                                </td>
                                <td colSpan={2}>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ TrustFundInstitution: e.target.value });
                                        }}
                                        value={this.state.TrustFundInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ TrustFundValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.TrustFundValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.TrustFundValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                TrustFundValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>Line of Credit</td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ LineOfCreditInstitution: e.target.value });
                                        }}
                                        value={this.state.LineOfCreditInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ LineOfCreditCurrentBalance: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.LineOfCreditCurrentBalance)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.LineOfCreditCurrentBalance;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                LineOfCreditCurrentBalance: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ LineOfCreditMonthlyPayment: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.LineOfCreditMonthlyPayment)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.LineOfCreditMonthlyPayment;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                LineOfCreditMonthlyPayment: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Life Insurance Cash Value
                                </td>
                                <td colSpan={2}>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ LifeInsuranceCashInstitution: e.target.value });
                                        }}
                                        value={this.state.LifeInsuranceCashInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ LifeInsuranceCashValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.LifeInsuranceCashValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.LifeInsuranceCashValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                LifeInsuranceCashValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>Other</td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherInstitution1: e.target.value });
                                        }}
                                        value={this.state.OtherInstitution1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherCurrentBalance1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherCurrentBalance1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherCurrentBalance1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherCurrentBalance1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherMonthlyPayment1: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.OtherMonthlyPayment1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherMonthlyPayment1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherMonthlyPayment1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>Real Estate Assets</td>
                                <td>Location</td>
                                <td>% Owned</td>
                                <td>Value ($)</td>
                                <td>Mortgage Holder</td>
                                <td>Mo. Rental Income</td>
                                <td>Current Balance</td>
                                <td>Mo. Pmt. ($)</td>
                            </tr>
                            <tr>
                                <td>
                                    Primary Residence
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ PrimaryResidenceLocation: e.target.value });
                                        }}
                                        value={this.state.PrimaryResidenceLocation}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ PrimaryResidencePercentOwned: e.target.value });
                                        }}
                                        value={this.state.PrimaryResidencePercentOwned}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ PrimaryResidenceValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.PrimaryResidenceValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.PrimaryResidenceValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                PrimaryResidenceValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ PrimaryResidenceMortgageHolder: e.target.value });
                                        }}
                                        value={this.state.PrimaryResidenceMortgageHolder}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ PrimaryResidenceMonthlyRentalIncome: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.PrimaryResidenceMonthlyRentalIncome)}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ PrimaryResidenceCurrentBalance: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.PrimaryResidenceCurrentBalance)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.PrimaryResidenceCurrentBalance;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                PrimaryResidenceCurrentBalance: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ PrimaryResidenceMonthlyPayment: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.PrimaryResidenceMonthlyPayment)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.PrimaryResidenceMonthlyPayment;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                PrimaryResidenceMonthlyPayment: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other Personal Residence
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherResidenceLocation: e.target.value });
                                        }}
                                        value={this.state.OtherResidenceLocation}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherResidencePercentOwned: e.target.value });
                                        }}
                                        value={this.state.OtherResidencePercentOwned}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherResidenceValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherResidenceValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherResidenceValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherResidenceValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherResidenceMortgageHolder: e.target.value });
                                        }}
                                        value={this.state.OtherResidenceMortgageHolder}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherResidenceMonthlyRentalIncome: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherResidenceMonthlyRentalIncome)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherResidenceMonthlyRentalIncome;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherResidenceMonthlyRentalIncome: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherResidenceCurrentBalance: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherResidenceCurrentBalance)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherResidenceCurrentBalance;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherResidenceCurrentBalance: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherResidenceMonthlyPayment: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.OtherResidenceMonthlyPayment)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherResidenceMonthlyPayment;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherResidenceMonthlyPayment: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Rental Property #1
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalLocation1: e.target.value });
                                        }}
                                        value={this.state.RentalLocation1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalPercentOwned1: e.target.value });
                                        }}
                                        value={this.state.RentalPercentOwned1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalValue1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RentalValue1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalValue1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalValue1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalMortgageHolder1: e.target.value });
                                        }}
                                        value={this.state.RentalMortgageHolder1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalMonthlyRentalIncome1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RentalMonthlyRentalIncome1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalMonthlyRentalIncome1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalMonthlyRentalIncome1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalBalance1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RentalBalance1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalBalance1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalBalance1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalMonthlyPayment1: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.RentalMonthlyPayment1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalMonthlyPayment1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalMonthlyPayment1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Rental Property #2
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalLocation2: e.target.value });
                                        }}
                                        value={this.state.RentalLocation2}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalPercentOwned2: e.target.value });
                                        }}
                                        value={this.state.RentalPercentOwned2}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalValue2: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RentalValue2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalValue2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalValue2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalMortgageHolder2: e.target.value });
                                        }}
                                        value={this.state.RentalMortgageHolder2}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalMonthlyRentalIncome2: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RentalMonthlyRentalIncome2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalMonthlyRentalIncome2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalMonthlyRentalIncome2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalBalance2: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RentalBalance2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalBalance2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalBalance2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalMonthlyPayment2: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.RentalMonthlyPayment2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalMonthlyPayment2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalMonthlyPayment2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Rental Property #3
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalLocation3: e.target.value });
                                        }}
                                        value={this.state.RentalLocation3}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalPercentOwned3: e.target.value });
                                        }}
                                        value={this.state.RentalPercentOwned3}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalValue3: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RentalValue3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalValue3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalValue3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalMortgageHolder3: e.target.value });
                                        }}
                                        value={this.state.RentalMortgageHolder3}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalMonthlyRentalIncome3: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RentalMonthlyRentalIncome3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalMonthlyRentalIncome3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalMonthlyRentalIncome3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalBalance3: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RentalBalance3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalBalance3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalBalance3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RentalMonthlyPayment3: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.RentalMonthlyPayment3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RentalMonthlyPayment3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RentalMonthlyPayment3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RealEstateOtherLocation1: e.target.value });
                                        }}
                                        value={this.state.RealEstateOtherLocation1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RealEstateOtherPercentOwned1: e.target.value });
                                        }}
                                        value={this.state.RealEstateOtherPercentOwned1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RealEstateOtherValue1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RealEstateOtherValue1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RealEstateOtherValue1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RealEstateOtherValue1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RealEstateOtherMortgageHolder1: e.target.value });
                                        }}
                                        value={this.state.RealEstateOtherMortgageHolder1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RealEstateOtherMonthlyRentalIncome1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RealEstateOtherMonthlyRentalIncome1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RealEstateOtherMonthlyRentalIncome1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RealEstateOtherMonthlyRentalIncome1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RealEstateOtherBalance1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.RealEstateOtherBalance1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RealEstateOtherBalance1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RealEstateOtherBalance1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ RealEstateOtherMonthlyPayment1: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.RealEstateOtherMonthlyPayment1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.RealEstateOtherMonthlyPayment1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                RealEstateOtherMonthlyPayment1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>Business Assets</td>
                                <td>Business Name</td>
                                <td>% Owned</td>
                                <td>Value ($)</td>
                                <td>Business Debts</td>
                                <td>Institution</td>
                                <td>Current Balance</td>
                                <td>Mo. Pmt. ($)</td>
                            </tr>
                            <tr>
                                <td>
                                    Business #1
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessName1: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessName1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetPercentOwned1: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetPercentOwned1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetPercentValue1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetPercentValue1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetPercentValue1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetPercentValue1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessDebts1: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessDebts1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessInstitution1: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessInstitution1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessCurrentBalance1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetBusinessCurrentBalance1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetBusinessCurrentBalance1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetBusinessCurrentBalance1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessMonthlyPayment1: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetBusinessMonthlyPayment1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetBusinessMonthlyPayment1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetBusinessMonthlyPayment1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Business #2
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessName2: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessName2}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetPercentOwned2: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetPercentOwned2}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetPercentValue2: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetPercentValue2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetPercentValue2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetPercentValue2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessDebts2: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessDebts2}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessInstitution2: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessInstitution2}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessCurrentBalance2: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetBusinessCurrentBalance2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetBusinessCurrentBalance2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetBusinessCurrentBalance2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessMonthlyPayment2: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetBusinessMonthlyPayment2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetBusinessMonthlyPayment2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetBusinessMonthlyPayment2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Business #3
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessName3: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessName3}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetPercentOwned3: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetPercentOwned3}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetPercentValue3: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetPercentValue3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetPercentValue3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetPercentValue3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessDebts3: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessDebts3}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessInstitution3: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessInstitution3}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessCurrentBalance3: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetBusinessCurrentBalance3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetBusinessCurrentBalance3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetBusinessCurrentBalance3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessMonthlyPayment3: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetBusinessMonthlyPayment3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetBusinessMonthlyPayment3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetBusinessMonthlyPayment3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Business #4
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessName4: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessName4}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetPercentOwned4: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetPercentOwned4}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetPercentValue4: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetPercentValue4)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetPercentValue4;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetPercentValue4: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessDebts4: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessDebts4}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessInstitution4: e.target.value });
                                        }}
                                        value={this.state.BusinessAssetBusinessInstitution4}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessCurrentBalance4: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetBusinessCurrentBalance4)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetBusinessCurrentBalance4;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetBusinessCurrentBalance4: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ BusinessAssetBusinessMonthlyPayment4: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.BusinessAssetBusinessMonthlyPayment4)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.BusinessAssetBusinessMonthlyPayment4;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                BusinessAssetBusinessMonthlyPayment4: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>Other Assets</td>
                                <td></td>
                                <td></td>
                                <td>Value ($)</td>
                                <td>Other Secured Loans</td>
                                <td>Institution</td>
                                <td>Current Balance</td>
                                <td>Mo. Pmt. ($)</td>
                            </tr>
                            <tr>
                                <td>
                                    Current Boat
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherAssetsCurrentBoatInstitution: e.target.value });
                                        }}
                                        value={this.state.OtherAssetsCurrentBoatInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherAssetCurrentBoatValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherAssetCurrentBoatValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherAssetCurrentBoatValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherAssetCurrentBoatValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    Boat Loan
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansBoatInstitution1: e.target.value });
                                        }}
                                        value={this.state.OtherSecuredLoansBoatInstitution1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansBoatCurrentBalance1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherSecuredLoansBoatCurrentBalance1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherSecuredLoansBoatCurrentBalance1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherSecuredLoansBoatCurrentBalance1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansBoatMonthlyPayment1: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.OtherSecuredLoansBoatMonthlyPayment1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherSecuredLoansBoatMonthlyPayment1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherSecuredLoansBoatMonthlyPayment1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2nd Boat, RV or Aircraft
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherAssets2ndBoatInstitution: e.target.value });
                                        }}
                                        value={this.state.OtherAssets2ndBoatInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherAsset2ndBoatValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherAsset2ndBoatValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherAsset2ndBoatValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherAsset2ndBoatValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    Boat/RV or Aircraft Loan
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansRVAircraftInstitution: e.target.value });
                                        }}
                                        value={this.state.OtherSecuredLoansRVAircraftInstitution}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>

                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansRVAircraftCurrentBalance: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherSecuredLoansRVAircraftCurrentBalance)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherSecuredLoansRVAircraftCurrentBalance;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherSecuredLoansRVAircraftCurrentBalance: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansRVAircraftMonthlyPayment: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.OtherSecuredLoansRVAircraftMonthlyPayment)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherSecuredLoansRVAircraftMonthlyPayment;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherSecuredLoansRVAircraftMonthlyPayment: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Automobiles
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherAssetAutomobilesValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherAssetAutomobilesValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherAssetAutomobilesValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherAssetAutomobilesValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    Other
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansOtherInstitution1: e.target.value });
                                        }}
                                        value={this.state.OtherSecuredLoansOtherInstitution1}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>

                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansOtherCurrentBalance1: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherSecuredLoansOtherCurrentBalance1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherSecuredLoansOtherCurrentBalance1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherSecuredLoansOtherCurrentBalance1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansOtherMonthlyPayment1: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.OtherSecuredLoansOtherMonthlyPayment1)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherSecuredLoansOtherMonthlyPayment1;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherSecuredLoansOtherMonthlyPayment1: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Art & Jewelry
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherAssetArtJeweleryValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherAssetArtJeweleryValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherAssetArtJeweleryValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherAssetArtJeweleryValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    Other
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansOtherInstitution2: e.target.value });
                                        }}
                                        value={this.state.OtherSecuredLoansOtherInstitution2}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>

                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansOtherCurrentBalance2: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherSecuredLoansOtherCurrentBalance2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherSecuredLoansOtherCurrentBalance2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherSecuredLoansOtherCurrentBalance2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansOtherMonthlyPayment2: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.OtherSecuredLoansOtherMonthlyPayment2)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherSecuredLoansOtherMonthlyPayment2;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherSecuredLoansOtherMonthlyPayment2: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherAssetOtherValue: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherAssetOtherValue)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherAssetOtherValue;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherAssetOtherValue: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    Other
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansOtherInstitution3: e.target.value });
                                        }}
                                        value={this.state.OtherSecuredLoansOtherInstitution3}
                                        style={{ width: '100%' }}
                                    ></input>
                                </td>

                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansOtherCurrentBalance3: e.target.value }, function () {
                                                this.calculateNetWorth();
                                            });
                                        }}
                                        value={this.formatCurrency(this.state.OtherSecuredLoansOtherCurrentBalance3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherSecuredLoansOtherCurrentBalance3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherSecuredLoansOtherCurrentBalance3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ OtherSecuredLoansOtherMonthlyPayment3: e.target.value });
                                        }}
                                        value={this.formatCurrency(this.state.OtherSecuredLoansOtherMonthlyPayment3)}
                                        style={{ width: '100%' }}
                                        onBlur={(e) => {
                                            var val = this.state.OtherSecuredLoansOtherMonthlyPayment3;
                                            if (e.target.value && e.target.value.startsWith('$') && !e.target.value.includes('.')) {
                                                val = val + '.00';
                                            }
                                            else if (e.target.value && e.target.value.startsWith('$') && e.target.value.includes('.')) {
                                                var spl = e.target.value.split(".");
                                                if (spl[1].length == 0) {
                                                    val = val + '00';
                                                }

                                                if (spl[1].length == 1) {
                                                    val = val + '0';
                                                }
                                            }
                                            this.setState({
                                                OtherSecuredLoansOtherMonthlyPayment3: val
                                            });
                                        }}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={8}>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Total Assets</td>
                                <td align="left" valign="middle">
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ TotalAssets: e.target.value });
                                        }}
                                        value={this.state.TotalAssets}
                                        style={{ width: '100%' }}
                                        disabled={true}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>Total Liabilities</td>
                                <td align="left" valign="middle">
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ TotalLiabilities: e.target.value });
                                        }}
                                        value={this.state.TotalLiabilities}
                                        style={{ width: '100%' }}
                                        disabled={true}
                                    ></input>
                                </td>
                            </tr>
                            <tr>
                                <td>Net Worth<br /><label style={{ fontSize: '10px', fontWeight: '400', marginBottom: '0' }}>(Total Assets minus Total Liabilities)</label></td>
                                <td align="left" valign="middle">
                                    <input type="text"
                                        onChange={(e) => {
                                            this.setState({ NetWorth: e.target.value });
                                        }}
                                        value={this.state.NetWorth}
                                        style={{ width: '100%' }}
                                        disabled={true}
                                    ></input>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td align="left" valign="middle">
                                    Have you ever had a boat loan before?
                                </td>
                                <td>
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="boatloan"
                                            style={{ marginRight: '5px' }}
                                            onChange={(e) => {
                                                this.setState({ HadBoatLoanBefore: e.target.checked ? "Yes" : "No" });
                                            }}
                                            checked={this.state.HadBoatLoanBefore === "Yes"}
                                        ></input>
                                        <label>Yes</label>
                                        <input type="radio"
                                            name="boatloan"
                                            style={{ marginRight: '5px' }}
                                            onChange={(e) => {
                                                this.setState({ HadBoatLoanBefore: e.target.checked ? "No" : "Yes" });
                                            }}
                                            checked={this.state.HadBoatLoanBefore === "No"}
                                        ></input>
                                        <label>No</label>
                                        {this.state.HadBoatLoanBefore === "Yes" ?
                                            <TextInput
                                                Type="text"
                                                Label="Lending Bank(s)"
                                                TextChanged={(e) => {
                                                    this.setState({ HadBoatLoanBeforeLendingBanks: e });
                                                }}
                                                Value={this.state.HadBoatLoanBeforeLendingBanks ?? ""}
                                                Style={{ width: '10vw' }}
                                            />
                                            : null}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" valign="middle">
                                    Have you filed bankruptcy in the last 7 years?
                                </td>
                                <td>
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="bankruptcy"
                                            style={{ marginRight: '5px' }}
                                            onChange={(e) => {
                                                this.setState({ BankruptcyLast7Years: e.target.checked ? "Yes" : "No" });
                                            }}
                                            checked={this.state.BankruptcyLast7Years === "Yes"}
                                        ></input>
                                        <label>Yes</label>
                                        <input type="radio"
                                            name="bankruptcy"
                                            style={{ marginRight: '5px' }}
                                            onChange={(e) => {
                                                this.setState({ BankruptcyLast7Years: e.target.checked ? "No" : "Yes" });
                                            }}
                                            checked={this.state.BankruptcyLast7Years === "No"}
                                        ></input>
                                        <label>No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" valign="middle">
                                    Are you obligated to pay alimony or child support?
                                </td>
                                <td>
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="alimony"
                                            style={{ marginRight: '5px' }}
                                            onChange={(e) => {
                                                this.setState({ AlimonyChildSupport: e.target.checked ? "Yes" : "No" });
                                            }}
                                            checked={this.state.AlimonyChildSupport === "Yes"}
                                        ></input>
                                        <label>Yes</label>
                                        <input type="radio"
                                            name="alimony"
                                            style={{ marginRight: '5px' }}
                                            onChange={(e) => {
                                                this.setState({ AlimonyChildSupport: e.target.checked ? "No" : "Yes" });
                                            }}
                                            checked={this.state.AlimonyChildSupport === "No"}
                                        ></input>
                                        <label>No</label>
                                        {this.state.AlimonyChildSupport === "Yes" ?
                                            <TextInput
                                                Type="custom"
                                                Label="Monthly Obligation"
                                                TextChanged={(e) => {
                                                    this.setState({ AlimonyChildSupportObligation: e });
                                                }}
                                                Value={this.formatCurrency(this.state.AlimonyChildSupportObligation)}
                                                Style={{ width: '10vw' }}
                                            />
                                            : null}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" valign="middle">
                                    Are any of your personal assets jointly owned?
                                </td>
                                <td>
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="jointlyowned"
                                            style={{ marginRight: '5px' }}
                                            onChange={(e) => {
                                                this.setState({ AssetsJointlyOwned: e.target.checked ? "Yes" : "No" });
                                            }}
                                            checked={this.state.AssetsJointlyOwned === "Yes"}
                                        ></input>
                                        <label>Yes</label>
                                        <input type="radio"
                                            name="jointlyowned"
                                            style={{ marginRight: '5px' }}
                                            onChange={(e) => {
                                                this.setState({ AssetsJointlyOwned: e.target.checked ? "No" : "Yes" });
                                            }}
                                            checked={this.state.AssetsJointlyOwned === "No"}
                                        ></input>
                                        <label>No</label>
                                        {this.state.AssetsJointlyOwned === "Yes" ?
                                            <TextInput
                                                Type="text"
                                                Label="Joint Major Asset Holder"
                                                TextChanged={(e) => {
                                                    this.setState({ AssetsJointlyOwnedMajorAssetHolder: e });
                                                }}
                                                Value={this.state.AssetsJointlyOwnedMajorAssetHolder ?? ""}
                                                Style={{ width: '10vw' }}
                                            />
                                            : null}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" valign="middle">
                                    Are you a defendant in any suit or legal action?
                                </td>
                                <td>
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="legalaction"
                                            style={{ marginRight: '5px' }}
                                            onChange={(e) => {
                                                this.setState({ DefendantInLawsuit: e.target.checked ? "Yes" : "No" });
                                            }}
                                            checked={this.state.DefendantInLawsuit === "Yes"}
                                        ></input>
                                        <label>Yes</label>
                                        <input type="radio"
                                            name="legalaction"
                                            style={{ marginRight: '5px' }}
                                            onChange={(e) => {
                                                this.setState({ DefendantInLawsuit: e.target.checked ? "No" : "Yes" });
                                            }}
                                            checked={this.state.DefendantInLawsuit === "No"}
                                        ></input>
                                        <label>No</label>
                                        {this.state.DefendantInLawsuit === "Yes" ?
                                            <TextInput
                                                Type="text"
                                                Label="Please Explain"
                                                TextChanged={(e) => {
                                                    this.setState({ DefendantInLawsuitExplain: e });
                                                }}
                                                Value={this.state.DefendantInLawsuitExplain ?? ""}
                                                Style={{ width: '10vw' }}
                                            />
                                            : null}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" valign="middle">
                                    Please indicate your primary business bank<br />relationship, if applicable
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Primary Business Bank Relationship"
                                        TextChanged={(e) => {
                                            this.setState({ PrimaryBusinessBankRelationship: e });
                                        }}
                                        Value={this.state.PrimaryBusinessBankRelationship ?? ""}
                                        Style={{ width: '250px' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan={2} align="left" valign="bottom" style={{ paddingTop: '15px' }}>
                                    <div className="disclaimer">
                                        Every person who signs this statement confirms that the information above is true and accurate to the best of their knowledge,
                                        and understands that this informationwill be relayed upon when determining whether or not credit will be granted in conjunction
                                        with the accompanying loan application.
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">
                                    <div style={{ display: 'flex', flexDirection: 'row', width: 'fit-content', alignItems: 'center', justifyContent: 'flex-start' }}>
                                        <input
                                            type="checkbox"
                                            checked={this.state.DiscloserAcknowledgement ?? false}
                                            onChange={(e) => {
                                                this.setState({ DiscloserAcknowledgement: !this.state.DiscloserAcknowledgement })
                                            }}
                                            style={{ marginRight: '5px' }}
                                        />
                                        <label style={{ marginBottom: '0', fontSize: '12px' }}>I acknowledge that I have read the disclosers and will be providing a digital signature when submitting this loan application.</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        Label="Borrower Signature"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerSignature: e });
                                                        }}
                                                        Value={this.state.BorrowerSignature ?? ""}
                                                        Style={{ width: '250px', fontFamily: 'Imperial Script', fontSize: '18px', marginBottom: '7px' }}
                                                    />
                                                </td>
                                                <td>
                                                    &nbsp;&nbsp;&nbsp;
                                                </td>
                                                <td>
                                                    {localStorage.getItem("APP_TYPE") != null && localStorage.getItem("APP_TYPE") == "Individual" ? null :
                                                        <TextInput
                                                            Type="text"
                                                            Label="CoBorrower Signature"
                                                            TextChanged={(e) => {
                                                                this.setState({ CoBorrowerSignature: e });
                                                            }}
                                                            Value={this.state.CoBorrowerSignature ?? ""}
                                                            Style={{ width: '250px', fontFamily: 'Imperial Script', fontSize: '18px', marginBottom: '7px' }}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="text"
                                                        Label="Borrower Name"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerSignatureName: e });
                                                        }}
                                                        Value={this.state.BorrowerSignatureName ?? ""}
                                                        Style={{ width: '250px', marginBottom: '7px' }}
                                                    />
                                                </td>
                                                <td>
                                                    &nbsp;&nbsp;&nbsp;
                                                </td>
                                                <td>
                                                    {localStorage.getItem("APP_TYPE") != null && localStorage.getItem("APP_TYPE") == "Individual" ? null :
                                                        <TextInput
                                                            Type="text"
                                                            Label="CoBorrower Name"
                                                            TextChanged={(e) => {
                                                                this.setState({ CoBorrowerSignatureName: e });
                                                            }}
                                                            Value={this.state.CoBorrowerSignatureName ?? ""}
                                                            Style={{ width: '250px', marginBottom: '7px' }}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextInput
                                                        Type="date"
                                                        Label="Date"
                                                        TextChanged={(e) => {
                                                            this.setState({ BorrowerSignatureDate: e });
                                                        }}
                                                        Value={this.state.BorrowerSignatureDate != undefined ? this.sqlToJsDate(this.state.BorrowerSignatureDate) : this.setState({ BorrowerSignatureDate: new Date().toLocaleDateString('en-CA') })}
                                                        Style={{ width: '130px' }}
                                                    />
                                                </td>
                                                <td>
                                                    &nbsp;&nbsp;&nbsp;
                                                </td>
                                                <td>
                                                    {localStorage.getItem("APP_TYPE") != null && localStorage.getItem("APP_TYPE") == "Individual" ? null :
                                                        <TextInput
                                                            Type="date"
                                                            Label="Date"
                                                            TextChanged={(e) => {
                                                                this.setState({ CoBorrowerSignatureDate: e });
                                                            }}
                                                            Value={this.state.CoBorrowerSignatureDate != undefined ? this.sqlToJsDate(this.state.CoBorrowerSignatureDate) : this.setState({ CoBorrowerSignatureDate: new Date().toLocaleDateString('en-CA') })}
                                                            Style={{ width: '130px' }}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="footer-center-content">
                    <table style={{ height: '100%' }}>
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading}
                                        onClick={() => {
                                            this.save(1);
                                        }}
                                    >Back
                                    </button>
                                </td>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading}
                                        onClick={() => {
                                            this.save();
                                        }}
                                        disabled={!this.state.DiscloserAcknowledgement}
                                    >
                                        Done
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}