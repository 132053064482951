import React, { Component } from 'react';
import { Container, Row, Card, CardBody } from 'reactstrap';
import '../layout/site.css';

export class RowItem extends Component {
    static displayName = RowItem.name;

    constructor(props) {
        super(props);

        this.state = {            

        };

    }

    render() {
        return (
            <Container className='row-item'>
                <Card className='card-item'>
                    <CardBody>
                        <Row>
                            {this.props.children}
                        </Row>
                    </CardBody>
                </Card>
            </Container>            
        )
    }
}