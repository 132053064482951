import authService from "../api-authorization/AuthorizeService"

const AdminFunctions = {
    isAdminAsync: async function() {
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Admin/IsAdminAsync`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        return data;
    },
    handleAdminUser: async function(isAdmin, adminFunction, nonAdminFunction) {
        if(isAdmin) {
            if(adminFunction){ adminFunction(); };            
        } else {
            if(nonAdminFunction){ nonAdminFunction(); };            
        }
    },    
    getRoleIdByName: async function(name) {        
        const token = await authService.getAccessToken();
        const response = await fetch(`api/Admin/GetRoleIdByNameAsync?roleName=${name}`, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        return data.id;
    }
}

export default AdminFunctions;