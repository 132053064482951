import React, { Component } from 'react';
import { _fetch } from './_fetch';
import FileHelper from './FileHelper';
import { Spinner } from 'reactstrap';
import Toast from './toast.js';
import TextInput from './TextInput';
import NavBox from './navbox';

export class Reporting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            reps: [],
            status: [],
            repsCheckedAll: false,
            isSalesRep: false,
            userId: ""
        };
    }

    async componentDidMount() {
        try {
            let id = sessionStorage.getItem("ID");
            if (id !== 0 && id !== null) {
                this.setState({ Id: id, CreditApplicationId: id, ShowNavBox: true });
            }
            else {
                this.setState({ Id: 0, CreditApplicationId: 0, ShowNavBox: true });
            }

            var userId = sessionStorage.getItem("USER_ID");

            this.setState({ userId: userId, loading: true }, function () {
                const f = new _fetch();

                f.fetchValue("/creditapplication/getrole?userId=" + userId)
                    .then((data) => {
                        if (data == "Sales Rep") {
                            this.setState({ isSalesRep: true, loading: false });
                            this.getCreditApplicationStatuses();
                        }
                        else {
                            this.setState({ isSalesRep: false, loading: false });
                            this.getCreditApplicationStatuses();
                            this.getSalesReps();
                        }
                    });
            });
        }
        catch (err) {
            console.log('Error in componentDidMount - ' + err);
            this.setState({ loading: false });
        }
    }

    async search() {
        try {
            this.setState({ SearchResults: [], loading: true });

            const f = new _fetch()

            var dateFrom = this.state.DateFrom ?? null;
            var dateTo = this.state.DateTo ?? null;
            var userId = this.state.userId;

            var data = {
                From: dateFrom,
                To: dateTo,
                Status: this.state.status,
                SalesReps: this.state.reps,
                UserId: userId
            }

            f.post("/creditapplication/reportingsearch", data)
                .then((data) => {
                    this.setState({ SearchResults: data, loading: false })
                });

        }
        catch (err) {
            this.setState({ loading: false });
            console.log('search - ' + err);
        }
    }

    async getSalesReps() {
        var fetch = _fetch();
        fetch.get("/creditapplication/GetSalesReps")
            .then((data) => {
                this.setState({ reps: data });
            })
    }

    async getCreditApplicationStatuses() {
        var fetch = _fetch();
        fetch.get("/creditapplication/GetCreditApplicationStatuses")
            .then((data) => {
                this.setState({ status: data });
            })
    }

    repChecked(id, checked) {
        var _reps = this.state.reps;
        var index = _reps.findIndex(f => f.id == id);
        var _selectAll = true;

        _reps[index].checked = checked;

        for (var i = 0; i < this.state.reps.length; i++) {
            if (_reps[i].checked == false) {
                _selectAll = false
            }
        }

        this.setState({ reps: _reps, repsCheckedAll: _selectAll });
    }

    statusChecked(id, checked) {
        var _status = this.state.status;
        var index = _status.findIndex(f => f.id == id);

        _status[index].checked = checked;

        this.setState({ status: _status });
    }

    repsSelectAll(checked) {
        var _reps = this.state.reps;

        for (var i = 0; i < this.state.reps.length; i++) {
            _reps[i].checked = checked;
        }

        this.setState({ reps: _reps, repsCheckedAll: checked });
    }

    async exportReport() {
        try {
            this.setState({ loading: true });
            let filename;
            let tempfilename = "CreditApplicationReport.csv";

            const f = new _fetch()

            var dateFrom = this.state.DateFrom ?? null;
            var dateTo = this.state.DateTo ?? null;
            var userId = this.state.userId;

            var data = {
                From: dateFrom,
                To: dateTo,
                Status: this.state.status,
                SalesReps: this.state.reps,
                UserId: userId
            }

            await f.postBlob("/creditapplication/exportreport", data)
                .then((res) => {
                    const header = res.headers.get('Content-Disposition');
                    const parts = header.split(';');
                    if (parts[1]) {
                        filename = parts[1].split('=')[1];
                    }
                    else {
                        filename = tempfilename;
                    }
                    return res.blob();
                })
                .then(data => {
                    FileHelper.DownloadFile(data, filename, "text/csv");
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    console.log('error in exportToCSV - ' + error.message);
                    this.setState({ loading: false });
                });

        }
        catch (err) {
            this.setState({ loading: false });
            console.log('exportReport - ' + err);
        }
    }

    render() {
        return (
            <div className="container-top">
                <NavBox
                    ShowNavBox={this.state.ShowNavBox}
                    ClientNumber={this.state.CreditApplicationId}
                    SaveOnClick={(e) => { }}
                />
                <Toast
                    toastText={this.state.toastMessage}
                    listItems={this.state.toastItems}
                    showToast={this.state.showToast}
                    onToastClose={() => this.setState({ showToast: false })}
                    autoHideToastDelay={10000}
                />
                <div className="container-for-fixed-header">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }
                    <div style={{ width: '56vw', height: 'fit-content', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '4vh', marginBottom: '1vh' }}>
                        <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                            <table className={"statusesTable"} cellPadding={0} cellSpacing={0}>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} align="left"><b>Status</b></td>
                                    </tr>
                                    {this.state.status && this.state.status?.length > 0 ?
                                        this.state.status?.map((item, i) =>
                                            <tr key={i}>
                                                <td>
                                                    <input type={"checkbox"} name={"status"} checked={item.checked} onChange={(e) => this.statusChecked(item.id, e.target.checked)}></input>
                                                </td>
                                                <td>
                                                    {item.name}
                                                </td>
                                            </tr>
                                        ) : null}
                                </tbody>
                            </table>
                        </div>
                        {!this.state.isSalesRep ?
                            <div style={{ marginLeft: '30px', maxHeight: '200px', overflow: 'auto' }}>
                                <table className={"repsTable"} cellPadding={0} cellSpacing={0}>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2} align="left"><b>Sales Reps</b></td>
                                        </tr>
                                        <tr key={-1}>
                                            <td>
                                                <input type={"checkbox"} name={"rep"} checked={this.state.repsCheckedAll} onChange={(e) => { this.repsSelectAll(e.target.checked) }}></input>
                                            </td>
                                            <td>
                                                Select All
                                            </td>
                                        </tr>
                                        {this.state.reps && this.state.reps?.length > 0 ?
                                            this.state.reps?.map((item, i) =>
                                                <tr key={i}>
                                                    <td>
                                                        <input type={"checkbox"} name={"rep"} checked={item.checked} onChange={(e) => this.repChecked(item.id, e.target.checked)}></input>
                                                    </td>
                                                    <td>
                                                        {item.name}
                                                    </td>
                                                </tr>
                                            ) : null}
                                    </tbody>
                                </table>
                            </div> : null}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignSelf: 'flex-end', height: '100%', paddingLeft: '1vw' }}>
                            <table className="dateRangeTable">
                                <tbody>
                                    <tr>
                                        <td>
                                            <TextInput
                                                Type="date2"
                                                Label="Date From"
                                                TextChanged={(e) => {
                                                    this.setState({ DateFrom: e });
                                                }}
                                                Value={this.state.DateFrom ?? ""}
                                                Style={{ width: '8vw' }}
                                            />
                                        </td>
                                        <td>
                                            <TextInput
                                                Type="date2"
                                                Label="Date To"
                                                TextChanged={(e) => {
                                                    this.setState({ DateTo: e });
                                                }}
                                                Value={this.state.DateTo ?? ""}
                                                Style={{ width: '8vw' }}
                                            />
                                        </td>
                                        <td valign="bottom">
                                            <button
                                                className="creditAppNextUpload"
                                                style={{ whiteSpace: 'nowrap' }}
                                                disabled={this.state.loading ? true : false}
                                                onClick={() => {
                                                    this.search();
                                                }}>
                                                Search
                                            </button>
                                        </td>
                                        <td valign="bottom">
                                            <button
                                                className="creditAppNextUpload"
                                                style={{ whiteSpace: 'nowrap' }}
                                                disabled={this.state.loading ? true : false}
                                                onClick={() => {
                                                    this.exportReport();
                                                }}>
                                                Export to CSV
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ maxWidth: '56vw', maxHeight: '31.5vh', overflow: 'auto' }}>
                        <table className="gridTable" style={{ maxWidth: '56vw' }}>
                            <thead>
                                <tr>
                                    <th>Sales Rep</th>
                                    <th>App Submission Date</th>
                                    <th>Invoice Date</th>
                                    <th>Borrower Last Name</th>
                                    <th>Lender</th>
                                    <th>Loan Amount</th>
                                    <th>Fee %</th>
                                    <th>Fee Dollar Amount</th>
                                    <th>Loan Processing Amount</th>
                                    <th>Dealer Referral Name</th>
                                    <th>Dealer Referral Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.SearchResults && this.state.SearchResults?.length > 0 ?
                                    this.state.SearchResults?.map((item, idx) =>
                                        <tr key={idx}>
                                            <td>{item.salesRep}</td>
                                            <td>{item.appSubmissionDate}</td>
                                            <td>{item.invoiceDate}</td>
                                            <td>{item.borrowerLastName}</td>
                                            <td>{item.lender}</td>
                                            <td>{item.loanAmount}</td>
                                            <td>{item.feePercent}</td>
                                            <td>{item.feeAmount}</td>
                                            <td>{item.loanProcessingAmount}</td>
                                            <td>{item.dealerReferralName}</td>
                                            <td>{item.dealerReferralAmount}</td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={11} align="center">No search results found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="footer">
                    <table style={{ width: '100%', height: '100%' }}>
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        );
    }
}