import React, { Component } from 'react';
import { _fetch } from './_fetch';
import { Spinner } from 'reactstrap';
import Toast from './toast.js';
import TextInput from './TextInput';
import NavBox from './navbox';

export class Vessel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            fuel: [{ id: 1, name: 'Gas' }, { id: 2, name: 'Diesel' }, { id: 3, name: 'Electric' }],
            type: [{ id: 1, name: 'Inboard' }, { id: 2, name: 'I/O' }, { id: 3, name: 'Outboard' }],
            materials: [{ id: 1, name: 'FBGL' }, { id: 2, name: 'ALUM' }]
        };
    }

    async componentDidMount() {
        let id = sessionStorage.getItem("ID");
        if (id !== 0 && id !== null) {
            this.getVessel(id);
            this.setState({ CreditApplicationId: id, ShowNavBox: true });
        }
    }

    async getVessel(id) {
        try {
            this.setState({ loading: true });

            var fetch = _fetch();
            fetch.get("/vessel/getvessel?id=" + id)
                .then((data) => {
                    var _state = this.state;
                    for (const key in data) {
                        if (data[key] != null) {
                            var _key = key.charAt(0).toUpperCase() + key.slice(1);
                            var _value = data[key];
                            _state[_key] = _value;
                        }
                    }

                    this.setState(_state, function () {
                        this.setState({ loading: false });
                    });
                })
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('getVessel - ' + err);
        }
    }

    formatCurrency(value) {
        if (value == undefined) return "";
        var _values = value.toString().replace(/,/g, '').replace('$', '');

        var dec = "";
        var _value = "";

        var elements = _values.split('.');
        if (elements?.length == 1) {
            _value = _values.split('.')[0];
        }
        else if (elements?.length > 1) {
            _value = _values.split('.')[0];
            dec = "." + _values.split('.')[1].substring(0, 2);
        }
        else {
            _value = _values;
        }
        if (_value.length > 0) {
            return new Intl.NumberFormat(undefined, {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }).format(_value) + dec;
        }
    }

    save(skipToast) {
        try {
            this.setState({ loading: true });

            var fetch = _fetch();
            fetch.post("/vessel/savevessel", this.state)
                .then((data) => {
                    if (data.id != 0) {
                        if (skipToast !== 1) {
                            this.setState({
                                showToastSuccess: true,
                                toastMessageSuccess: 'Saved successfully'
                            });
                        }
                        this.setState({ loading: false, Id: data.id });
                    }
                    else {
                        this.setState({
                            showToast: true,
                            toastMessage: 'There was an issue saving your information. Please try again',
                            loading: false
                        });
                    }
                });
        }
        catch (err) {
            this.setState({ loading: false });
            console.log('save - ' + err);
        }
    }

    render() {
        return (
            <div className="container-top">
                <NavBox
                    ShowNavBox={this.state.ShowNavBox}
                    ClientNumber={this.state.CreditApplicationId}
                    LastName={this.state.BorrowerLastName}
                    SaveOnClick={(e) => this.save(1)}
                />
                <Toast
                    toastText={this.state.toastMessage}
                    listItems={this.state.toastItems}
                    showToast={this.state.showToast}
                    onToastClose={() => this.setState({ showToast: false })}
                    autoHideToastDelay={5000}
                />
                <Toast
                    toastText={this.state.toastMessageSuccess}
                    showToast={this.state.showToastSuccess}
                    onToastClose={() => this.setState({ showToastSuccess: false })}
                    autoHideToastDelay={5000}
                    isSuccessToast={true}
                />
                <div className="container-for-fixed-header">
                    {this.state.loading === true ?
                        <div>
                            <Spinner color="#000" children='' style={{ position: 'fixed', top: '50%', left: '50%' }} />
                        </div>
                        : null
                    }

                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td align="left" colSpan={2}>
                                    <label style={{ fontSize: '22px', fontWeight: '500', color: '#596db3' }}>Vessel Information</label>
                                </td>
                            </tr>
                            <tr>
                                <td align="left" style={{ paddingTop: '10px' }}>
                                    Purchase or Refinance?
                                </td>
                                <td align="left" style={{ paddingTop: '10px' }}>
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="purchase"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.CreditApplicationData?.purchaseOrRefinance === 'Purchase'}
                                            onChange={(e) => {
                                                var creditApp = this.state.CreditApplicationData;
                                                creditApp.purchaseOrRefinance = 'Purchase';

                                                this.setState({
                                                    CreditApplicationData: creditApp
                                                });
                                            }}
                                        >
                                        </input>
                                        <label>Purchase</label>
                                        <input type="radio"
                                            name="purchase"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.CreditApplicationData?.purchaseOrRefinance === 'Refinance'}
                                            onChange={(e) => {
                                                var creditApp = this.state.CreditApplicationData;
                                                creditApp.purchaseOrRefinance = 'Refinance';

                                                this.setState({ CreditApplicationData: creditApp });
                                            }}
                                        >
                                        </input>
                                        <label>Refinance</label>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td align="left">
                                    New or Used?
                                </td>
                                <td align="left">
                                    <div className="radioGroup">
                                        <input type="radio"
                                            name="new"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.CreditApplicationData?.purchaseOrRefinance === 'Refinance' ? undefined : this.state.CreditApplicationData?.newOrUsed === 'New'}
                                            onChange={(e) => {
                                                var creditApp = this.state.CreditApplicationData;
                                                creditApp.newOrUsed = 'New';

                                                this.setState({ creditApp });
                                            }
                                            }
                                            disabled={this.state.CreditApplicationData?.purchaseOrRefinance === 'Refinance'}
                                        >
                                        </input>
                                        <label>New</label>
                                        <input type="radio"
                                            name="new"
                                            style={{ marginRight: '5px' }}
                                            checked={this.state.CreditApplicationData?.purchaseOrRefinance === 'Refinance' ? undefined : this.state.CreditApplicationData?.newOrUsed === 'Used'}
                                            onChange={(e) => {
                                                var creditApp = this.state.CreditApplicationData;
                                                creditApp.newOrUsed = 'Used';

                                                this.setState({ CreditApplicationData: creditApp });
                                            }
                                            }
                                            disabled={this.state.CreditApplicationData?.purchaseOrRefinance === 'Refinance'}
                                        >
                                        </input>
                                        <label>Used</label>
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>


                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Model Year"
                                        MaxNumericValue={9999}
                                        MaxTextLength={4}
                                        TextChanged={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.vesselYear = e == "" ? null : e;

                                            this.setState({ CreditApplicationData: creditApp });
                                        }}
                                        Value={this.state.CreditApplicationData?.vesselYear}
                                        Style={{ width: '5vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Year Built"
                                        MaxNumericValue={9999}
                                        MaxTextLength={4}
                                        TextChanged={(e) => {
                                            this.setState({ YearBuilt: e });
                                        }}
                                        Value={this.state.YearBuilt}
                                        Style={{ width: '5vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput type="text"
                                        Type="text"
                                        TextChanged={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.vesselManufacturer = e;

                                            this.setState({ CreditApplicationData: creditApp });
                                        }}
                                        Value={this.state.CreditApplicationData?.vesselManufacturer}
                                        Label="Builder / Manufacturer"
                                        Style={{ width: '11vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput type="text"
                                        Type="text"
                                        TextChanged={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.vesselModel = e;

                                            this.setState({ CreditApplicationData: creditApp });
                                        }}
                                        Value={this.state.CreditApplicationData?.vesselModel}
                                        Label="Model"
                                        Style={{ width: '11vw' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Length"
                                        MaxNumericValue={9999}
                                        MaxTextLength={8}
                                        TextChanged={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.vesselLength = e == "" ? null : e;

                                            this.setState({ CreditApplicationData: creditApp });
                                        }}
                                        Value={this.state.CreditApplicationData?.vesselLength}
                                        Style={{ width: '5vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Beam"
                                        MaxNumericValue={9999}
                                        MaxTextLength={4}
                                        TextChanged={(e) => {
                                            this.setState({ Beam: e });
                                        }}
                                        Value={this.state.Beam}
                                        Style={{ width: '5vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput type="text"
                                        Type="text"
                                        TextChanged={(e) => this.setState({ Draft: e })}
                                        Value={this.state.Draft}
                                        Label="Draft"
                                        Style={{ width: '5vw' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Hull ID"
                                        MaxTextLength={12}
                                        TextChanged={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.hullIdentificationNumber = e;

                                            this.setState({ CreditApplicationData: creditApp });
                                        }}
                                        Value={this.state.CreditApplicationData?.hullIdentificationNumber}
                                        Style={{ width: '8vw', textTransform: 'uppercase' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Official #"
                                        MaxTextLength={12}
                                        TextChanged={(e) => {
                                            this.setState({ OfficialNumber: e });
                                        }}
                                        Value={this.state.OfficialNumber}
                                        Style={{ width: '8vw' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <select
                                        value={this.state.Material}
                                        //style={{ marginTop: '0px' }}
                                        onChange={(e) => {
                                            this.setState({ Material: e.target.value })
                                        }}
                                    >
                                        <option key={0} value={0}>Material</option>
                                        {this.state.materials?.map((item, idx) =>
                                            <option key={idx} value={item.name}>{item.name}</option>
                                        )}
                                    </select>
                                </td>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Gross Tons"
                                        MaxTextLength={8}
                                        TextChanged={(e) => {
                                            this.setState({ GrossTons: e == "" ? null : e });
                                        }}
                                        Value={this.state.GrossTons}
                                        Style={{ width: '8vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Net Tons"
                                        MaxTextLength={8}
                                        TextChanged={(e) => {
                                            this.setState({ NetTons: e == "" ? null : e });
                                        }}
                                        Value={this.state.NetTons}
                                        Style={{ width: '8vw' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <br />

                    <table cellPadding={3} className="engineTable">
                        <thead>
                            <tr>
                                <th align="center"><b>Engine</b></th>
                                <th align="center"><b>Year</b></th>
                                <th align="center"><b>Manufacturer</b></th>
                                <th align="center"><b>Serial Number</b></th>
                                <th align="center"><b>HP</b></th>
                                <th align="center"><b>Fuel</b></th>
                                <th align="center"><b>Type</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Engine #1</td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => {
                                            this.setState({ Engine1Year: e.target.value })
                                        }}
                                        value={this.state.Engine1Year}
                                        style={{ width: '50px' }}
                                        onKeyDown={(e) => {
                                            var val = e.target.value;

                                            if (val.length == 4 && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.vesselEngineManufacturer = e.target.value;

                                            this.setState({ CreditApplicationData: creditApp })
                                        }}
                                        value={this.state.CreditApplicationData?.vesselEngineManufacturer}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        style={{ textTransform: 'uppercase' }}
                                        onChange={(e) => {
                                            this.setState({ Engine1SerialNumber: e.target.value })
                                        }}
                                        value={this.state.Engine1SerialNumber}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.vesselHP = e.target.value == "" ? null : e.target.value;

                                            this.setState({ CreditApplicationData: creditApp })
                                        }}
                                        value={this.state.CreditApplicationData?.vesselHP}
                                        style={{ width: '50px' }}
                                        onKeyDown={(e) => {
                                            var val = e.target.value;

                                            if (val.length == 4 && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <select
                                        value={this.state.CreditApplicationData?.vesselEngineFuel}
                                        style={{ marginTop: '0px' }}
                                        onChange={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.vesselEngineFuel = e.target.value;

                                            this.setState({ CreditApplicationData: creditApp })
                                        }}
                                    >
                                        <option key={0} value={0}>Select</option>
                                        {this.state.fuel?.map((item, idx) =>
                                            <option key={idx} value={item.name}>{item.name}</option>
                                        )}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        value={this.state.Engine1Type}
                                        style={{ marginTop: '0px' }}
                                        onChange={(e) => {
                                            this.setState({ Engine1Type: e.target.value })
                                        }}
                                    >
                                        <option key={0} value={0}>Select</option>
                                        {this.state.type?.map((item, idx) =>
                                            <option key={idx} value={item.name}>{item.name}</option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Engine #2</td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => {
                                            this.setState({ Engine2Year: e.target.value })
                                        }}
                                        value={this.state.Engine2Year}
                                        style={{ width: '50px' }}
                                        onKeyDown={(e) => {
                                            var val = e.target.value;

                                            if (val.length == 4 && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            this.setState({ Engine2Make: e.target.value })
                                        }}
                                        value={this.state.Engine2Make}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        style={{ textTransform: 'uppercase' }}
                                        onChange={(e) => {
                                            this.setState({ Engine2SerialNumber: e.target.value })
                                        }}
                                        value={this.state.Engine2SerialNumber}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => {
                                            this.setState({ Engine2HP: e.target.value })
                                        }}
                                        value={this.state.Engine2HP}
                                        style={{ width: '50px' }}
                                        onKeyDown={(e) => {
                                            var val = e.target.value;

                                            if (val.length == 4 && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <select
                                        value={this.state.Engine2Fuel}
                                        style={{ marginTop: '0px' }}
                                        onChange={(e) => {
                                            this.setState({ Engine2Fuel: e.target.value })
                                        }}
                                    >
                                        <option key={0} value={0}>Select</option>
                                        {this.state.fuel?.map((item, idx) =>
                                            <option key={idx} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        value={this.state.Engine2Type}
                                        style={{ marginTop: '0px' }}
                                        onChange={(e) => {
                                            this.setState({ Engine2Type: e.target.value })
                                        }}
                                    >
                                        <option key={0} value={0}>Select</option>
                                        {this.state.type?.map((item, idx) =>
                                            <option key={idx} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Engine #3</td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => {
                                            this.setState({ Engine3Year: e.target.value })
                                        }}
                                        value={this.state.Engine3Year}
                                        style={{ width: '50px' }}
                                        onKeyDown={(e) => {
                                            var val = e.target.value;

                                            if (val.length == 4 && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            this.setState({ Engine3Make: e.target.value })
                                        }}
                                        value={this.state.Engine3Make}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        style={{ textTransform: 'uppercase' }}
                                        onChange={(e) => {
                                            this.setState({ Engine3SerialNumber: e.target.value })
                                        }}
                                        value={this.state.Engine3SerialNumber}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => {
                                            this.setState({ Engine3HP: e.target.value })
                                        }}
                                        value={this.state.Engine3HP}
                                        style={{ width: '50px' }}
                                        onKeyDown={(e) => {
                                            var val = e.target.value;

                                            if (val.length == 4 && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <select
                                        value={this.state.Engine3Fuel}
                                        style={{ marginTop: '0px' }}
                                        onChange={(e) => {
                                            this.setState({ Engine3Fuel: e.target.value })
                                        }}
                                    >
                                        <option key={0} value={0}>Select</option>
                                        {this.state.fuel?.map((item, idx) =>
                                            <option key={idx} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        value={this.state.Engine3Type}
                                        style={{ marginTop: '0px' }}
                                        onChange={(e) => {
                                            this.setState({ Engine3Type: e.target.value })
                                        }}
                                    >
                                        <option key={0} value={0}>Select</option>
                                        {this.state.type?.map((item, idx) =>
                                            <option key={idx} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Engine #4</td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => {
                                            this.setState({ Engine4Year: e.target.value })
                                        }}
                                        value={this.state.Engine4Year}
                                        style={{ width: '50px' }}
                                        onKeyDown={(e) => {
                                            var val = e.target.value;

                                            if (val.length == 4 && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        onChange={(e) => {
                                            this.setState({ Engine4Make: e.target.value })
                                        }}
                                        value={this.state.Engine4Make}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        style={{ textTransform: 'uppercase' }}
                                        onChange={(e) => {
                                            this.setState({ Engine4SerialNumber: e.target.value })
                                        }}
                                        value={this.state.Engine4SerialNumber}
                                    ></input>
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        onChange={(e) => {
                                            this.setState({ Engine4HP: e.target.value })
                                        }}
                                        value={this.state.Engine4HP}
                                        style={{ width: '50px' }}
                                        onKeyDown={(e) => {
                                            var val = e.target.value;

                                            if (val.length == 4 && e.key != 'Backspace' && e.key != 'Tab') e.preventDefault();
                                        }}
                                    ></input>
                                </td>
                                <td>
                                    <select
                                        value={this.state.Engine4Fuel}
                                        style={{ marginTop: '0px' }}
                                        onChange={(e) => {
                                            this.setState({ Engine4Fuel: e.target.value })
                                        }}
                                    >
                                        <option key={0} value={0}>Select</option>
                                        {this.state.fuel?.map((item, idx) =>
                                            <option key={idx} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </td>
                                <td>
                                    <select
                                        value={this.state.Engine4Type}
                                        style={{ marginTop: '0px' }}
                                        onChange={(e) => {
                                            this.setState({ Engine4Type: e.target.value })
                                        }}
                                    >
                                        <option key={0} value={0}>Select</option>
                                        {this.state.type?.map((item, idx) =>
                                            <option key={idx} value={item.id}>{item.name}</option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />

                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Vessel Name"
                                        TextChanged={(e) => {
                                            this.setState({ VesselName: e });
                                        }}
                                        Value={this.state.VesselName}
                                        Style={{ width: '8vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Old Vessel Name"
                                        TextChanged={(e) => {
                                            this.setState({ OldVesselName: e });
                                        }}
                                        Value={this.state.OldVesselName}
                                        Style={{ width: '8vw' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Hailing Port"
                                        TextChanged={(e) => {
                                            this.setState({ HailingPort: e });
                                        }}
                                        Value={this.state.HailingPort}
                                        Style={{ width: '8vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Current Location"
                                        TextChanged={(e) => {
                                            this.setState({ CurrentLocation: e });
                                        }}
                                        Value={this.state.CurrentLocation}
                                        Style={{ width: '8vw' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Summer Mooring"
                                        TextChanged={(e) => {
                                            this.setState({ SummerMooring: e });
                                        }}
                                        Value={this.state.SummerMooring}
                                        Style={{ width: '8vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Summer Mooring Address"
                                        TextChanged={(e) => {
                                            this.setState({ SummerMooringAddress: e });
                                        }}
                                        Value={this.state.SummerMooringAddress}
                                        Style={{ width: '12vw' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Winter Mooring"
                                        TextChanged={(e) => {
                                            this.setState({ WinterMooring: e });
                                        }}
                                        Value={this.state.WinterMooring}
                                        Style={{ width: '8vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Winter Mooring Address"
                                        TextChanged={(e) => {
                                            this.setState({ WinterMooringAddress: e });
                                        }}
                                        Value={this.state.WinterMooringAddress}
                                        Style={{ width: '12vw' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Trailer Year"
                                        TextChanged={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.trailerYear = e == "" ? null : e;

                                            this.setState({ CreditApplicationData: creditApp });
                                        }}
                                        Value={this.state.CreditApplicationData?.trailerYear}
                                        Style={{ width: '40px' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="number"
                                        Label="Tender Year"
                                        TextChanged={(e) => {
                                            this.setState({ TenderYear: e });
                                        }}
                                        Value={this.state.TenderYear}
                                        Style={{ width: '40px' }}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Trailer Manufacturer"
                                        TextChanged={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.trailerMake = e;

                                            this.setState({ CreditApplicationData: creditApp });
                                        }}
                                        Value={this.state.CreditApplicationData?.trailerMake}
                                        Style={{ width: '12vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Tender Manufacturer"
                                        TextChanged={(e) => {
                                            this.setState({ TenderMake: e });
                                        }}
                                        Value={this.state.TenderMake}
                                        Style={{ width: '12vw' }}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Trailer Model"
                                        TextChanged={(e) => {
                                            var creditApp = this.state.CreditApplicationData;
                                            creditApp.trailerModel = e;

                                            this.setState({ CreditApplicationData: creditApp });
                                        }}
                                        Value={this.state.CreditApplicationData?.trailerModel}
                                        Style={{ width: '12vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Tender Model"
                                        TextChanged={(e) => {
                                            this.setState({ TenderModel: e });
                                        }}
                                        Value={this.state.TenderModel}
                                        Style={{ width: '12vw' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Trailer VIN"
                                        TextChanged={(e) => {
                                            this.setState({ TrailerVIN: e });
                                        }}
                                        Value={this.state.TrailerVIN}
                                        Style={{ width: '12vw', textTransform: 'uppercase' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="text"
                                        Label="Tender VIN"
                                        TextChanged={(e) => {
                                            this.setState({ TenderVIN: e });
                                        }}
                                        Value={this.state.TenderVIN}
                                        Style={{ width: '12vw', textTransform: 'uppercase' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table cellPadding={3}>
                        <tbody>
                            <tr>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Trailer Value"
                                        TextChanged={(e) => {
                                            this.setState({ TrailerValue: e });
                                        }}
                                        Value={this.formatCurrency(this.state.TrailerValue)}
                                        Style={{ width: '12vw' }}
                                    />
                                </td>
                                <td>
                                    <TextInput
                                        Type="custom"
                                        Label="Tender Value"
                                        TextChanged={(e) => {
                                            this.setState({ TenderValue: e });
                                        }}
                                        Value={this.formatCurrency(this.state.TenderValue)}
                                        Style={{ width: '12vw' }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="footer-center-content">
                    <table style={{ width: '100%', height: '100%' }}>
                        <tbody>
                            <tr>
                                <td align="center" valign="middle">
                                    <button
                                        className="creditAppNext"
                                        style={{ whiteSpace: 'nowrap' }}
                                        disabled={this.state.loading ? true : false}
                                        onClick={() => {
                                            this.save();
                                        }}>
                                        Save
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >
        )
    }
}